.popup {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(3, 41, 78, 0.56);
  z-index: 4;
}
/*
width: 35.401rem;
  height: 26.442rem;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
*/
.popup_inner1 {
  position: absolute;

  /* width: 35.401rem;
  height: 26.442rem; */
  width: 34rem;
  /*height: 16rem;*/
  height: auto;
  left: 25%;
  right: 25%;
  top: 25%;
  bottom: auto;
  margin: auto;
  padding-bottom: 2rem;
  background-color: white;
  border-top: 0.25rem #629ac4 solid;
}

#resetPwdContent {
  /* width: 100%; */
  display: grid;
  place-content: center;
}

.ErrorPopup {
  position: absolute;
  /*Change made to fit to animation page by Shlomy Akons 23/07/2020*/
  /*width: 83.5rem;
  height: 48rem;*/
  width: 100%;
  height: 100%;
  background-color: rgba(3, 41, 78, 0.56);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 4;

  /* background-color: rgba(0, 0, 0, 0.5); */
}

@keyframes pageAnimationErrorPopupInner {
  from {
    width: 1px;
    height: 1px;
    top: 50%;
    left: 50%;
  }
  to {
    width: 30rem;
    height: 16rem;
    top: 27%;
    left: 31.5%;
  }
}

.ErrorCloseButton {
  /* width: 2.261rem; */
  /* height: 2.131rem;
  width: 2.131rem; */
  height: 1.5rem;
  width: 1.5rem;
  background-color: #629ac4;
}
.ErrorPopupTitle {
  /* width: 8.688rem;
    height: 1.5rem;*/
  width: 100%;
  font-size: 1.25rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #629ac4;
  width: 80%;
  /*justify-content: center;
    align-content: center; */
  margin-top: 0%;
  margin-left: 10%;
  display: block;
}

#TokenDiv {
  margin-top: 10%;
  margin-left: 7% !important;
  display: flex;
  align-items: flex-start;
  place-items: flex-start;
  justify-content: flex-start;
  align-content: flex-start;
}

#VerificationCodeButtonsDiv {
  display: flex;
  width: 100%;
  margin-top: 3rem;
  /* margin-left: 22%; */
  /* margin-left: 2.4rem; */
  /* margin-left: 22%; */
  position: relative;
  justify-content: flex-end;
  align-content: flex-end;
  place-content: flex-end;
  padding-right: 2rem;
}
.ContactUsErrorLink {
  color: #629ac4;
  font-size: 0.85rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
}

@media only screen and (min-device-width: 1000px)
{
  .ErrorPopupInner {
    position: absolute;
    width: 30rem;
    height: 16rem;
    /* width: 66%;
      height: 59%; */
    left: 31.5%;
    /* right: 29%;
       */
    top: 27%;
    margin: 1%;
    /* padding: 5px; */
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    z-index: 4;
    background-color: white;
    animation-name: pageAnimationErrorPopupInner;
    animation-duration: 0.75s;
  }
  .ErrorPopupMessage {
    width: 25rem;
    /* height: 13.134rem; */
    font-size: 0.85rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.75;
    letter-spacing: normal;
    text-align: left;
    color: #252930;
    margin-top: 2%;
    margin-left: 10%;
    margin-right: 10%;
    display: block;
    visibility: hidden;
  }
  .popup_inner {
    position: absolute;
  
    /* width: 35.401rem;
    height: 26.442rem; */
    width: 30rem;
    /*height: 16rem;*/
    height: auto;
    left: 25%;
    right: 25%;
    top: 25%;
    bottom: auto;
    margin: auto;
    padding-bottom: 2rem;
    background-color: white;
    border-top: 0.25rem #629ac4 solid;
  }
  .ErrorPopupMessageToken {
    width: 25rem;
    /* height: 13.134rem; */
    font-size: 0.85rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.75;
    letter-spacing: normal;
    text-align: left;
    color: #252930;
    margin-top: 2%;
    margin-left: 10%;
    margin-right: 10%;
    display: block;
  }
  #verificationCode {
    width: 24rem !important;
  }
}

@media only screen and (min-width: 50px) and (max-width: 900px) {
  .ErrorPopup {
    position: absolute;
    /*Change made to fit to animation page by Shlomy Akons 23/07/2020*/
    /*width: 83.5rem;
    height: 48rem;*/
    width: 115%;
    height: 100%;
    background-color: rgba(3, 41, 78, 0.56);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    z-index: 4;
  
    /* background-color: rgba(0, 0, 0, 0.5); */
  }
  .ErrorPopupInner {
    position: absolute;
    width: 17rem;
    height: 16rem;
    top: 17%;
    left: 11.5%;
    margin: 1%;
    /* padding: 5px; */
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    z-index: 4;
    background-color: white;
  }
  .ErrorPopupMessage {
    width: 15rem;
    /* height: 13.134rem; */
    font-size: 0.85rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.75;
    letter-spacing: normal;
    text-align: left;
    color: #252930;
    margin-top: 2%;
    margin-left: 5%;
    margin-right: 10%;
    display: block;
    visibility: hidden;
  }
  .popup_inner {
    position: absolute;
  
    /* width: 35.401rem;
    height: 26.442rem; */
    width: 17rem;
    /*height: 16rem;*/
    height: auto;
    left: 11.5%;
    right: 25%;
    top: 17%;
    bottom: auto;
    margin: auto;
    padding-bottom: 2rem;
    background-color: white;
    border-top: 0.25rem #629ac4 solid;
  }
  .ErrorPopupMessageToken {
    width: 14rem;
    /* height: 13.134rem; */
    font-size: 0.85rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.75;
    letter-spacing: normal;
    text-align: left;
    color: #252930;
    margin-top: 2%;
    margin-left: 10%;
    margin-right: 10%;
    display: block;
  }
  #verificationCode {
    width: 13rem !important;
  }


  @media only screen and (min-width: 50px) and (max-width: 767px) {
    .ErrorPopup {
      position: absolute;
      /*Change made to fit to animation page by Shlomy Akons 23/07/2020*/
      /*width: 83.5rem;
      height: 48rem;*/
      width: 120%;
      height: 100%;
      background-color: rgba(3, 41, 78, 0.56);
      top: 0;
      left: -0.75rem;
      right: 0;
      bottom: 0;
      margin: auto;
      z-index: 4;
    
      /* background-color: rgba(0, 0, 0, 0.5); */
    }

    .ErrorPopupInner {
      position: absolute;
      width: 17rem;
      height: 16rem;
      top: 17%;
      left: 5%;
      margin: 1%;
      /* padding: 5px; */
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
      z-index: 4;
      background-color: white;
    }
  }
}



