.SideMenuLink a {
  text-decoration: none;
}

.SideMenuLink a:hover {
  text-decoration: none;
}

.SideMenuLink li:active,
.SideMenuLink li:hover,
.SideMenuLink li:visited,
.SideMenuLink a.active .SideMenuLink a.hover,
.SideMenuLink a.visited,
.SideMenuLink span.active,
.SideMenuLink span.hover,
.SideMenuLink span.visited {
  text-decoration: none;
}

.HomeMenuActive,
.AboutUsActive,
.ContactUsActive {
  border-right-color: #1046ce !important;
  border-right-width: 0.313rem;
  border-right-style: solid;
}

.PaymentsActive {
  border-right-color: #e1a417;
  border-right-width: 0.313rem;
  border-right-style: solid;
  color: #e1a417 !important;
}

.LandRegisterActive {
  border-right-color: #e25824;
  border-right-width: 0.313rem;
  border-right-style: solid;
  color: #e25824 !important;
}

.PersonalZoneActive {
  border-right-color: #279762;
  border-right-width: 0.313rem;
  border-right-style: solid;
  color: #279762 !important;
}

.AppFileTrackingActive {
  border-right-color: #71a543;
  border-right-width: 0.313rem;
  border-right-style: solid;
  color: #71a543 !important;
}

.HomeMenuTitleActive,
.AboutUsTitleActive,
.ContactUsTitleActive {
  color: #1046ce !important;
}

.PaymentTitleActive {
  color: #e1a417 !important;
}
.LandRegisterTitleActive {
  color: #e25824 !important;
}
.PersonalZoneTitleActive {
  color: #279762 !important;
}

.AppFileTrackingTitleActive {
  color: #71a543 !important;
}
#SideBarMenuDiv {
  margin: 0 0 0 0;
  padding: 0 0 0 0;
  width: 21.3% !important;
  /*height: 100% !important;*/
  height: 34.2rem;
  background-color: #ffffff;
  margin-left: 0rem;
  z-index: 1;
  float: left;
  height:41.5rem !important;
}
#SideMenu {
  width: 100%;
  height: 100%;
  list-style: none;
  margin: 0 0 0 0;
  padding: 0 0 0 0;
  /* position: relative; */
  /* width: 21.3%;
  height: 100%; */
  background-color: #ffffff;
  margin-left: 0rem;
  margin-top:1rem;
}

.SideMenuLink {
  width: 100%;
  height: 2.259rem;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.33);
  background-color: #ffffff;
  margin-bottom: 0.702rem;
  padding-top: 0.45rem;
  vertical-align: center;
  font-family: Verdana, Geneva, Tahoma, sans-serif;

  color: #252930;
  text-decoration: none;
}

/* in sidebar.jsx page */
/* .SideMenuLink::a.active {
  color: #e14e17;
} */
/* in sidebar.jsx page */
.sideMenuImg {
  width: 0.822rem;
  height: 0.9rem;
  object-fit: contain;
  margin-left: 1.597rem;
  margin-top: 0.206rem;
  margin-right: 1.45rem;
  vertical-align: center;
}
/* in sidebar.jsx page */

.sideMenuTitle {
  width: 2.438rem;
  /* height: 0.938rem; */
  height: 0.938rem;
  font-size: 0.85rem;
  /* font-size: 0.75rem;  */
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: #252930;
}

/* in sidebar.jsx page */
.BeforeHomeSpace {
  /*height: 2.794rem !important; */ /*Remarked by Shlomy Akons 09/06/2021*/
  width: 100%;
}
/* in sidebar.jsx page */
.BeforeOnlineServicesSpace {
  /*height: 3.134rem;*/
  width: 100%;
  text-align: center;
}
/* in sidebar.jsx page */
.Online-Services_Title {
  width: 100%;
  height: 1.313rem;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 1.063rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.24;
  letter-spacing: normal;
  text-align: center;
  color: #252930;
  vertical-align: bottom;
  padding-top: 1rem;
}

/*********************************************** NEW ***********************************************************************/

/* default styles here for older browsers. 
       I tend to go for a 600px - 960px width max but using percentages
    */
/* To Dropdown navbar dropdown on hover */

/* MOBILES */
@media only screen and (min-device-width: 50px) and (max-device-width: 900px) {
  #MenuBtn {
    color: black;
    margin-top: 1rem;
    margin-left: 4rem;
    height:45px;
  }

  #SideBarMenuDiv {
    /* position: fixed;
    left: 0%;
    top: 0;
    margin: 0 0 0 0;
    padding: 0 0 0 0; */

    width: auto !important;
    height: auto !important;

    /* height: 34.2rem; */
    background-color: #ffffff;
    margin-left: -0.5rem;
    /* margin-top: -15%; */
    /*margin-top: 6%;*/
    /*margin-top:1.5rem; /*Changed By Shlomy Akons 23/11/2020 for compat along lanf reg and payment*/
    margin-top:3.5rem; /*Changed By Shlomy Akons 20/05/2021*/
    position: relative;
    z-index: 2;
    /* box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.33); */
  }


/*
  #mainMenuNav.show ul{
    animation-name: pageAnimationSideBarMobile;
    animation-duration: 0.75s;
  }
  */

  #mainMenuNav.hide ul{
    display: none;
  }


  @keyframes pageAnimationSideBarMobile {
    from {
      width: 22.5rem;
      height: 35rem;
      top: 4.5rem;
      left:-22.5rem;
    }
    to {
      width: 22.5rem;
      height: 35rem;
      top: 4.5rem;
      left:-0.5rem;
    }
  }

  @keyframes pageAnimationSideBarMobileReverse {
    from {
      width: 22.5rem;
      height: 35rem;
      top: 4.5rem;
      left:-0.5rem;
    }
    to {
      width: 22.5rem;
      height: 35rem;
      top: 4.5rem;
      left:-22.5rem;
    }
  }

  #mainMenuNav {
    position: absolute;
    min-width: 65% !important;
    width: 65% !important;
    height: 100% !important;
    /* background-color: rgba(3, 41, 78, 0.56); */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    z-index: 4;
    justify-content: center;
    align-content: center;
    background-color: #ffffff;
    position: relative;
    /* margin-left: 1.75rem; */
    margin-top: 1rem;
  }

  #SideMenu {
    /* min-width: 22rem !important; */
    /*width: 100% !important; 08/06/2021 Shlomy Akons change for sidemenu animation
    height: 100% !important;*/
    list-style: none;
    margin: 1rem 0rem 0rem 0rem;
    padding: 0 0 0;
    display: block;
    position: fixed;
    /* display: block; */
    /* position: relative; */
    background-color: #ffffff;
    /* margin-left: 1.75rem;
    margin-top: 1rem;
    z-index: 10; */
    /* margin-top: 1.55rem; */
  }

  .SideMenuLink {
    width: 100%;
    height: 2.259rem;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.33);
    background-color: #ffffff;
    margin-bottom: 0.702rem;
    padding-top: 0.45rem;
    vertical-align: center;
    font-family: Verdana, Geneva, Tahoma, sans-serif;

    color: #252930;
    text-decoration: none;
  }

  .sideMenuTitle {
    width: 2.438rem;
    height: 0.938rem;
    font-size: 0.85rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: left;
    color: #252930;
  }

  /* in sidebar.jsx page */
  .BeforeHomeSpace {
    height: 0.5rem !important;
    width: 100%;
  }
  /* in sidebar.jsx page */
  .BeforeOnlineServicesSpace {
    height: 0rem;
    width: 100%;
    /* text-align: center; */
  }
}

/* for all mobile/cellphone widths */
@media only screen and (min-width: 200px) and (max-width: 767px) and (orientation: portrait) {
  /* //Put your CSS here for 200px to 767px width devices (cover all mobile portrait width // */
}

/* for all mobile/cellphone widths */
@media only screen and (min-width: 50px) and (max-width: 767px) and (orientation: landscape) {
  /* //Put your CSS here for 200px to 767px width devices (cover all mobile portrait width // */
}

/* TABLETS*/
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  /* //Put your CSS here for 768px to 1024px width devices(covers all iPad portrait width //
    */
  .sideMenuTitle {
    width: 2.438rem;

    height: 0.938rem;

    font-size: 0.75rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: left;
    color: #252930;
  }
}

/* different techniques for iPad screening */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  /* For portrait layouts only */
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  /* For landscape layouts only */
}

/*DESKTOPS*/
/* (1920x1080) Full HD Display */
@media screen and (min-device-width: 1366px) and (max-device-width: 1999px) {
  /* in sidebar.jsx page */

  .sideMenuTitle {
    width: 2.438rem;
    /* height: 0.938rem; */
    height: 0.938rem;
    font-size: 0.85rem;
    /* font-size: 0.75rem;  */
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: left;
    color: #252930;
  }
}
/* (1366x768) WXGA Display */
@media screen and (min-device-width: 1025px) and (max-device-width: 1365px) {
  .sideMenuTitle {
    width: 2.438rem;
    /* height: 0.938rem; */
    height: 0.938rem;
    font-size: 0.6rem;
    /* font-size: 0.75rem;  */
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: left;
    color: #252930;
  }
}

@media only screen and (min-width: 767px) and (max-width: 959px) {
  .sideMenuTitle {
    width: 2.438rem;

    height: 0.938rem;
    font-size: 0.75rem;

    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: left;
    color: #252930;
  }
}
@media only screen and (min-width: 960px) and (max-width: 1024px) {
  /* styles for browsers larger than 1440px; */
  .sideMenuTitle {
    width: 2.438rem;
    /* height: 0.938rem; */
    height: 0.938rem;
    font-size: 0.65rem;
    /* font-size: 0.75rem;  */
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: left;
    color: #252930;
  }
}
@media only screen and (min-width: 2000px) {
  /* for sumo sized (mac) screens */
  .sideMenuTitle {
    width: 2.438rem;
    /* height: 0.938rem; */
    height: 0.938rem;
    font-size: 1rem;
    /* font-size: 0.75rem;  */
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: left;
    color: #252930;
  }
}

/*********************************************** NEW ***********************************************************************/

/* TABLETS*/
@media only screen and (min-width: 860px) and (max-width: 1024px) {

  #SideBarMenuDiv {
    margin: 0 0 0 0;
    padding: 0 0 0 0;
    width: 30% !important;
    background-color: #ffffff;
    margin-left: 0rem;
    z-index: 1;
    float: left;
    height:41.5rem !important;
  }

  
  #SideMenu {
    width: 100% !important;
    height: 100% !important;
    list-style: none;
    margin: 0rem 0rem 0rem 0rem;
    padding: 0 0 0 0;
    background-color: #ffffff;
    margin-top: 1rem; 
  }

  .SideMenuLink {
    width: 100%;
    height: 2.259rem;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.33);
    background-color: #ffffff;
    margin-bottom: 0.702rem;
    padding-top: 0.45rem;
    vertical-align: center;
    font-family: Verdana, Geneva, Tahoma, sans-serif;

    color: #252930;
    text-decoration: none;
  }

  .sideMenuTitle {
    width: 2.438rem;
    height: 0.938rem;
    font-size: 0.85rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: left;
    color: #252930;
  }

  /* in sidebar.jsx page */
  .BeforeHomeSpace {
    height: 0.5rem !important;
    width: 100%;
  }
  /* in sidebar.jsx page */
  .BeforeOnlineServicesSpace {
    height: 0rem;
    width: 100%;
    /* text-align: center; */
  }


/*
  #SideBarMenuDiv {
    width: auto !important;
    height: auto !important;
    background-color: #ffffff;
    margin-left: -0.5rem;
    margin-top:2.5rem;
    position: relative;
    z-index: 2;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.33); 
  }*/
/*
  #MenuBtn {
    color: black;
    margin-top: 0.2rem !important;
    margin-left: 3rem !important;
    height:45px !important;
    width:45px !important;
  }*/



}