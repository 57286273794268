/* in home.jsx page */

#rightMenu {
  padding: 0%;
  margin-right:0% !important;
  margin-left:0% !important;
}

#workArea {
  float: right;
  margin-top: 0rem !important;
  margin-bottom: 0rem !important;
  padding-top: 1.479rem;
  padding-bottom: 1.479rem;
  justify-content: center !important;
  z-index: 1;
  background-color: #f3f7fa;
  /* max-height: 100% !important;
  height: 100% !important; */
  /* height: 30rem !important; */
  /* height: 33rem !important; */
  max-width: 78.5% !important;
  width: 78.5% !important;
  /*height: 100% !important;*/
  display: inline-block;
}
.addAnimation {
  width: 99.6%;
  /*position:absolute;*/
  animation-name: pageAnimationHome;
  animation-duration: 0.75s;
}

@media only screen and (min-width: 1400px) {
  .workAreaPageAnimation {
    max-height: 100% !important;
    /*height: 31rem;*/
    height: 100%;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    border-top: 0.177rem #1046ce solid;
    width: 99.6%;
    /*position:absolute;*/
    animation-name: pageAnimationHome;
    animation-duration: 0.75s;
  }
}

@media only screen and (width: 1366px) {
  .workAreaPageAnimation {
    max-height: 100% !important;
    height: 31rem;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    border-top: 0.177rem #1046ce solid;
    width: 63.2rem !important;
    position: absolute;
    animation-name: pageAnimationHome;
    animation-duration: 0.75s;
  }
}

.workAreaPage {
  /* width: 64.125rem !important;
    height: 28.5rem !important; */
  /* width: 64.125rem;
    height: 28.5rem; */
  width: 99.6% !important;
  max-height: 100% !important;
  height: 31rem !important;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  border-top: 0.177rem #1046ce solid;
}

@keyframes pageAnimationHome {
  from {
    width: 1px;
    height: 1px;
    top: 150px;
    left: 288px;
  }
  to {
    width: 63.2rem;
    height: 31rem;
    top: 103px;
    left: 323px;
  }
}
@media only screen and (min-width: 1000px){
/* in home.jsx page */
  .GeoDevPortalLinkDiv {
      /* width: 100% !important; */
    justify-content: center;
    align-content: center;
    margin-top: 8%;
    margin-left: 33%;
  }


  #wellcomeDiv {
    display: flex;
    justify-items: center;
    margin-left: 1.75rem;
    margin-right: 1.75rem;
    height: 100% !important;
    max-width: 100% !important;
    background-color: #f3f7fa;
  }

  #serviceFormsDiv {
    display: inline;
    margin-left: 3.942rem;
    margin-top: 1.905rem;
    display: inline-flex;
    width: 94%;
  }

  .WellcomeDesc1 {
    padding-left: 3.942rem !important;
    font-size: 0.85rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.19;
    letter-spacing: normal;
    text-align: left;
    color: #252930;
  }

  .WellcomeDesc2 {
    padding-left: 3.942rem !important;
    font-size: 0.85rem;
    font-size: 0.85rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.5;
    letter-spacing: normal;
    text-align: left;
    color: #252930;
  }
  #WellcomeTitleDiv {
    min-width: 64.125rem !important;
    min-height: 28.5rem !important;
  }
}
/* in wellcomeComponent.jsx page */

/* in wellcomeComponent.jsx page */
/* in wellcomeComponent.jsx page */
#Content {
  visibility: hidden;
}
.Welcome_Title {
  padding-top: 2.757rem;
  padding-left: 3.942rem;
  font-size: 1.5rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
  color: #629ac4;
}

.WellcomeDescDiv {
  margin-top: 2.36rem !important;
  margin-bottom: 1.905rem !important;
}

.WellcomeDesc1_CL::first-letter {
  font-weight: bold;
}

.serviceForm {
  display: inline;
  min-width: 16.222rem;
  max-width: 28%;
  height: 12rem;
  opacity: 1;
  box-shadow: 4px 4px 0 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #c2c2c2;
  background-color: #ffffff;
  margin-right: 0.826rem;
}

.serviceTitleDiv {
  width: 100%;
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #629ac4;
  display: inline-block;
}

.serviceImg {
  width: 1.044rem;
  height: 1.13rem;
  object-fit: contain;
  margin-top: 8%;
  margin-left: 1.5rem;
  vertical-align: text-bottom;
  z-index: 2;
  display: inline;
}

.serviceTitle,
.serviceTitle a span,
.serviceTitle a.active,
.serviceTitle a.hover,
.serviceTitle a.visited,
.serviceTitle span.active,
.serviceTitle span.hover,
.serviceTitle span.visited,
.serviceTitle span a.active,
.serviceTitle span a.hover,
.serviceTitle span a.visited {
  margin-top: 8%;
  margin-left: 0.5rem;
  display: inline;
  z-index: 2;
  display: inline;
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #629ac4;
  text-decoration: none;
}

.serviceFormDesc {
  width: 80%;
  padding: 0rem;
  margin-top: 5%;
  margin-left: 3.044rem;
  font-size: 0.85rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: left;
  color: #252930;
  overflow-wrap: wrap;
  display: inline-flexbox;
  z-index: 2;
}

.GeoDevPortalLink a,
.GeoDevPortalLink a:active,
.GeoDevPortalLink a:hover,
.GeoDevPortalLink a:visited,
.GeoDevPortalLink a span:active,
.GeoDevPortalLink a span:hover,
.GeoDevPortalLink a span:visited {
  color: #629ac4;
  font-size: 0.85rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  text-decoration: none;
}

/*********************************************** NEW ***********************************************************************/

/* default styles here for older browsers. 
       I tend to go for a 600px - 960px width max but using percentages
    */

/* MOBILES */
@media only screen and (min-width: 50px) and (max-width: 900px) {
  /* styles for mobile browsers smaller than 480px; (iPhone) */
  #rightMenu {
    padding: 0%;
    margin-right:4% !important;
    margin-left:7% !important;
  }
  #Content
  {
    width:20rem;
    margin-left:0.7rem;
    height: 120% !important;
  }
  #workArea {
    /* float: right; */
    position: absolute;
    /*left: -4rem;*/ /*Remarked By Shlomy Akons 19/11/2020 */
    top: 6rem !important;
    margin-top: 0.5rem !important;
    margin-bottom: 0rem !important;
    padding-top: 0rem;
    padding-bottom: 0rem;
    /* justify-content: center !important; */
    z-index: 1;
    /* background-color: #f3f7fa;
   */
    background-color: white;
    max-width: 100% !important;
    width: 100% ;
    display: inline-block;
    margin-left:1rem;
  }

  .workAreaPage {
    width: 99.9% !important;
    max-height: 100% !important;
    height: 100%;
    box-shadow: none;
    background-color: #ffffff;

    border: none;
  }

  .workAreaPageAnimation {
    max-height: 100% !important;
    height: 100%;
    box-shadow: none;
    background-color: #ffffff;
    border: none;
    width: 99.9%;
    animation-name: pageAnimationHome;
    animation-duration: 0.75s;
  }

  /**/

  /* in home.jsx page */
  #wellcomeDiv {
    display: inline-flex;
    justify-items: self-start;
    /*margin-left: 1.75rem;*/ /*Remarked by Shlomy Akons 23/11/2020 to fit main page in 360 * 640*/
    width: 20rem !important;
    background-color: white;
  }

  /* in wellcomeComponent.jsx page */

  #WellcomeTitleDiv {
    /* display: table-flex; */
    /*width: 100% !important;*/
    width: 20rem; /*Changed from 21.5 to 20 by Tallia*/
    height: 100% !important;
  }

  .Welcome_Title {
    display: none;
  }

  .WellcomeDescDiv {
    width: 98%;
    height: 98%;
  }
  .WellcomeDesc1 {
    font-size: 1rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    line-height: 1.21;
    letter-spacing: normal;
    text-align: left;
    color: #629ac4;
  }

  .WellcomeDesc1_CL::first-letter {
    font-weight: bold;
  }

  .WellcomeDesc2 {
    font-size: 1rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: normal;
    text-align: left;
    color: #252930;
  }

  #serviceFormsDiv {
    /*margin-left: 3.942rem; */ /*Remarked by Shlomy Akons 23/11/2020 to fit main page*/
    margin-top: 0.1rem;
    display: block;
    width: 100% !important;
    min-height: 99% !important;
    position: relative;
  }

  /*
  #serviceFormsDiv {
    display: inline;
    margin-left: 3.942rem;
    margin-top: 1rem;
    display: inline-flex;
    width: 94%;
  }
  */

  .serviceForm {
    display: block;
    min-width: 85%;
    /* min-width: 16.222rem; */

    /* max-width: 28%; */
    /*max-width: 85%;*/
    max-width: 20rem; /*Remarked by Shlomy Akons 23/11/2020 to fit main page*/ 
    /* height: 9.959rem; */
    height: 8rem;
    opacity: 1;
    box-shadow: 4px 4px 0 0 rgba(0, 0, 0, 0.16);
    border: solid 1px #c2c2c2;
    background-color: #ffffff;
    /* margin-right: 0.826rem; */
    margin-right: 0rem;
    margin-bottom: 1rem;
  }

  .serviceTitleDiv {
    width: 100%;
    font-size: 1.5rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #629ac4;
    display: inline-block;
  }

  .serviceImg {
    width: 1.044rem;
    height: 1.13rem;
    object-fit: contain;
    margin-top: 0.5rem;
    margin-left: 0.5rem;
    vertical-align: text-bottom;
    z-index: 2;
    display: inline;
  }

  serviceTitle,
  .serviceTitle a span,
  .serviceTitle a.active,
  .serviceTitle a.hover,
  .serviceTitle a.visited,
  .serviceTitle span.active,
  .serviceTitle span.hover,
  .serviceTitle span.visited,
  .serviceTitle span a.active,
  .serviceTitle span a.hover,
  .serviceTitle span a.visited {
    margin-top: 0.6rem;
    margin-left: 0.6rem;
    display: inline;
    z-index: 2;
    display: inline;
    font-size: 1.5rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #629ac4;
    text-decoration: none;
  }

  .serviceFormDesc {
    width: 100%;
    padding: 0rem;
    margin-top: 0.5rem;
    margin-left: 0.5rem;
    padding-left: 1.5rem;
    padding-right: 0.5rem;
    font-size: 0.8rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: normal;
    text-align: left;
    color: #252930;
    overflow-wrap: wrap;
    display: inline-flexbox;
    z-index: 2;
  }

  .GeoDevPortalLinkDiv {
    justify-content: center;
    align-content: center;
    margin-top: 4%;
    /*margin-left: 4.45rem;*/  /*Remarked by Shlomy Akons 23/11/2020 to fit main page in 360 * 640*/

    /*margin-left: 19.5%;*/ /*Remarked by Shlomy Akons 23/11/2020 to fit main page in 360 * 640*/
  }
  /* .GeoDevPortalLinkDiv {
    justify-content: center;
    align-content: center;
    margin-top: 8%;
    margin-left: 33%;
  } */
}

@media only screen and (min-device-width: 350px) and (max-device-width: 800px) {
  #Content
  {
    width:20rem;
    margin-left:0.7rem;
    height: 120% !important;
    padding-bottom: 5rem;
  }

  #workArea {
    /* float: right; */
    position: fixed;
    /*left: -4rem;*/ /*Remarked By Shlomy Akons 19/11/2020 */
    top: 6rem !important;
    margin-top: 0.5rem !important;
    margin-bottom: 0rem !important;
    padding-top: 0rem;
    padding-bottom: 0rem;
    /* justify-content: center !important; */
    z-index: 1;
    /* background-color: #f3f7fa;
   */
    background-color: white;
    max-width: 100% !important;
    width: 100% ;
    display: inline-block;
    margin-left:1rem;
  }
}

@media only screen and (min-device-width: 380px) and (max-device-width: 855px) {
  #Content
  {
    width:20rem;
    margin-left:0.7rem;
    height:120% !important;
  }

  #workArea {
    /* float: right; */
    position: absolute;
    /*left: -4rem;*/ /*Remarked By Shlomy Akons 19/11/2020 */
    top: 6rem !important;
    margin-top: 0.5rem !important;
    margin-bottom: 0rem !important;
    padding-top: 0rem;
    padding-bottom: 0rem;
    /* justify-content: center !important; */
    z-index: 1;
    /* background-color: #f3f7fa;
   */
    background-color: white;
    max-width: 100% !important;
    width: 100% ;
    display: inline-block;
    margin-left:1rem;
  }

}

/* for all mobile/cellphone widths */
@media only screen and (min-width: 50px) and (max-width: 767px) and (orientation: portrait) {
  /* //Put your CSS here for 200px to 767px width devices (cover all mobile portrait width // */
}

/* for all mobile/cellphone widths */
@media only screen and (min-width: 50px) and (max-width: 767px) and (orientation: landscape) {
  /* //Put your CSS here for 200px to 767px width devices (cover all mobile portrait width // */
}

/* TABLETS*/
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  /* //Put your CSS here for 768px to 1024px width devices(covers all iPad portrait width //
    */
}

/* different techniques for iPad screening */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  /* For portrait layouts only */
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  /* For landscape layouts only */
}

/*DESKTOPS*/
/* (1920x1080) Full HD Display */
@media screen and (min-device-width: 1366px) and (max-device-width: 1999px) {
  /* in sidebar.jsx page */
}
/* (1366x768) WXGA Display */
@media screen and (min-device-width: 1025px) and (max-device-width: 1365px) {
}

@media only screen and (min-width: 767px) and (max-width: 959px) {
}
@media only screen and (min-width: 960px) and (max-width: 1024px) {
  /* styles for browsers larger than 1440px; */
}
@media only screen and (min-width: 2000px) {
  /* for sumo sized (mac) screens */
}

/*********************************************** NEW ***********************************************************************/

/* TABLETS*/
@media only screen and (min-width: 860px) and (max-width: 1024px) {
  #Content {
  padding-left: 3rem;
  }

  .Welcome_Title {
    padding-top: 2.757rem;
    padding-left: 3rem;
    font-size: 1.5rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: normal;
    text-align: left;
    color: #629ac4;
  }

  .serviceForm {
    display: inline;
    min-width: 16.222rem;
    max-width: 28%;
    height: 12rem;
    opacity: 1;
    box-shadow: 4px 4px 0 0 rgba(0, 0, 0, 0.16);
    border: solid 1px #c2c2c2;
    background-color: #ffffff;
    margin-right: 0.826rem;
}

#serviceFormsDiv {
  display: inline;
  margin-left: 0rem;
  margin-top: 1.905rem;
  display: inline-flex;
  width: 94%;
}
  .workAreaPage{
    width:65% !important;
    margin-left:1rem;
    margin-top:0.5rem !important;
  }

  #workArea {
    /* float: right; */
    /*position: absolute;*/
    /*left: -4rem;*/ /*Remarked By Shlomy Akons 19/11/2020 */
    top: 4.5rem;
    margin-top: 0.5rem !important;
    margin-bottom: 0rem !important;
    margin-left:1.4rem !important;
    padding-top: 0rem;
    padding-bottom: 0rem;
    width:100% !important;
    /* justify-content: center !important; */
    z-index: 1;
    /* background-color: #f3f7fa;
  */
    background-color: #f3f7fa;
    /*background-color: white;*/
    max-width: 100% !important;
    width: 100% ;
    display: inline-block;
    margin-left:1rem;
    position: fixed;
  }

  #rightMenu {
    display:none;
  }
}
