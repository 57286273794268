@keyframes LogInPopupInnerAnimation {
  from {
    width: 1px;
    height: 1px;
    top: 50%;
    left: 50%;
  }
  to {
    width: 48rem;
    height: 32rem;
    top: 10%;
    left: 21%;
  }
}

@keyframes LogInRegisterPopupInnerAnimation {
  from {
    width: 1px;
    height: 1px;
    top: 50%;
    left: 50%;
  }
  to {
    width: 48rem;
    height: 35rem;
    top: 5%;
    left: 21%;
  }
}

.LogInTitle {
  align-items: center;
  margin-top: 0.16rem;
  /* width: 19.625rem;
    height: 1.813rem; */
  font-size: 1.5rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: center;
  color: #629ac4;
}

.LogInTitleJoin {
  align-items: center;
  margin-top: 0rem;
  /* width: 19.625rem;
    height: 1.813rem; */
  font-size: 1.5rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: center;
  color: #629ac4;
}

.LogInTitle3 {
  align-items: center;
  margin-top: 0rem;
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: center;
  color: #252930;
}

.OneTimeTokenTitle {
  /* width: 12.938rem;
    height: 1.5rem; */

  align-items: center;
  align-items: center;
  margin-top: 1.2rem;
  margin-bottom: 1.2rem;
  font-size: 1.25rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  color: #629ac4;
}

.LoginForm {
  display: grid;
  align-self: center;
  justify-self: center;
  align-items: center;
  justify-items: center;
  place-items: center;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin-top: 0rem;

  /* padding-left: 5rem;
  padding-right: 5rem; */
  width: 100% !important;
  word-wrap: none;
}

.LoginWithPasswordTitle {
  display: inline-block;
  width: 100%;
  /* width: 28.007rem;
    height: 4.392rem;
    font-family: Verdana; */
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: normal;
  text-align: center;
  color: #252930;
  margin-top: 3.23rem;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.LoginWithPasswordTitleMargin {
  margin-top: 3rem;
}

.RegisterTitleMargin {
  margin-top: 0.5rem;
}

.LoginWithPasswordLink a {
  width: 59.407rem;
  height: 34.357rem;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  color: #629ac4;
  text-decoration: underline;
}
.LoginWithPasswordLinkSpan {
  margin-left: 0.2rem;
  margin-top: 0.4rem;
  width: auto;
}
.LoginWithPasswordLink1 a {
  /* width: 59.407rem;
  height: 34.357rem; */
  /* box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16); */
  background-color: #ffffff;
  color: #629ac4;
  text-decoration: underline;
}

.LoginButtonsDiv {
  display: inline-flex;
  width: 100%;
  /* margin-left: 22%; */
  /* margin-left: 2.4rem; */
  position: relative;
  justify-content: flex-end;
  align-content: flex-end;
  place-content: flex-end;
  justify-content: center;
  align-content: center;
  place-content: center;
}
/*Standard Login Form Styles*/
#forgotPwdDiv {
  display: inline-flex;
  width: 100% !important;
  align-content: center !important;
  align-items: center !important;
  justify-items: center !important;
  align-items: center !important;
  justify-content: center;
  align-content: center;
  place-content: center;
  margin-top: 3rem;
}

.forgotPwdText {
  text-decoration: underline;
}

.errorMessage {
  display: inline-flex;
  width: 100%;
  color: red;
  font-size: 1rem;
  align-items: center;
  justify-items: center;
  justify-content: center;
  align-content: center;
}

input#NationalIdOrFullName {
  width: 100% !important;
}
input#CellularOrEmail {
  width: 100% !important;
}
input.NationalIdOrFullName {
  width: 14.25rem !important;
}

/* 
#notRegesterText {
  color: gray;
  font-size: small;
} */
/* #goToTokenLoginDiv {
  margin-top: -1rem;
  font-size: small;
} */

/* #goToTokenLoginLink {
  font-weight: bolder;
  font-size: small;
  color: black;
} */

#SideBarMenuLoginDiv {
  display: none;
}

.SideBarMenuDivLogin {
  display: none;
}

/*DESKTOPS*/

@media only screen and (min-width: 1000px){
  .LogInPopupInner {
    position: absolute;
    width: 48rem;
    height: 33rem;
    left: 21.3%;
    top: 10%;
  
    /* padding: 5px; */
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    border-top: 0.25rem #629ac4 solid;
    z-index: 4;
    animation-name: LogInPopupInnerAnimation;
    animation-duration: 0.75s;
  }
  .LogInRegisterPopupInner {
    position: absolute;
    width: 48rem;
    height: 35rem;
    left: 21.3%;
    top: 5%;
  
    /* padding: 5px; */
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    border-top: 0.25rem #629ac4 solid;
    z-index: 4;
    animation-name: LogInRegisterPopupInnerAnimation;
    animation-duration: 0.75s;
  }
  .FormButtonsDivLogin
  {
    width:100%;
    display: inline-flex;
    position: relative;
    justify-content: flex-end !important;
    align-content: flex-end !important;
    place-content: flex-end !important;
    justify-content: flex-end !important;
    align-content: flex-end !important;
    place-content: flex-end !important;
    margin: 0.5rem 0rem 0.5rem 0rem !important;
  }
  .FormButtonsDiv
  {
    width: 22rem !important;
  }
  .FormRegisterButtonsDiv
  {
    display: inline-flex;
    width: 100%;
    position: relative;
    justify-content: flex-end !important;
    align-content: flex-end !important;
    place-content: flex-end !important;
    justify-content: flex-end !important;
    align-content: flex-end !important;
    place-content: flex-end !important;
    margin: 0.5rem 0rem 0.5rem 0rem !important;
  }
  .FormButtonsVerificationDiv
  {
    width:22rem !important;
  }
  #LoginContent {
    visibility: hidden;
    width: 100%;
  }
  .LogInPopup {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(3, 41, 78, 0.56);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    z-index: 4;
    justify-content: center;
    align-content: center;
  
    /* background-color: rgba(0, 0, 0, 0.5); */
  }
  .LogInTitle2 {
    align-items: center;
    margin-top: 1rem;
    font-size: 1rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.19;
    letter-spacing: normal;
    text-align: center;
    color: #252930;
  }
  .HelpPopup {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(3, 41, 78, 0.56);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    z-index: 4;
    justify-content: center;
    align-content: center;
  
    /* background-color: rgba(0, 0, 0, 0.5); */
  }
  .HelpPopupInner {
    position: absolute;
    width: 50rem;
    height: 36em;
    left: 21.3%;
    top: 5%;
  
    /* padding: 5px; */
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    border-top: 0.25rem #629ac4 solid;
    z-index: 4;
    animation-name: LogInPopupInnerAnimation;
    animation-duration: 0.75s;
  }
  .HelpParagraphWrapper
  {
    overflow-y: auto;
    height:31.8rem;
  }
  .HelpParagraph {
    display: flex;
    align-items: center;
    place-items: center;
    margin-top: 0.5rem;
    margin-left: 5rem;
    margin-right: 5rem;
    font-size: 0.85rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #252930;
  }
  .HelpList {
    display: block;
    align-items: left;
    margin-top: 0.1rem;
    margin-left: 5rem;
    margin-right: 5rem;
    font-size: 0.85rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #252930;
  }
}

/* (1920x1080) Full HD Display */
@media screen and (max-width: 1920px) {
}
/* (1366x768) WXGA Display */
@media screen and (max-width: 1366px) {
}

@media only screen and (min-width: 960px) {
  /* styles for browsers larger than 960px; */
  .MinistryFullTitle {
    display: inline-block;
  }
  .MinistryShortTitle {
    display: none;
  }

  .LogInTitle4 {
    align-items: center;
    display: block;
    margin-left: 20%;
    margin-right: 20%;
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
    font-size: 0.75rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: center;
    color: #252930;
  }
}
@media only screen and (min-width: 1440px) {
  /* styles for browsers larger than 1440px; */
  .MinistryFullTitle {
    display: inline-block;
  }
  .MinistryShortTitle {
    display: none;
  }
}
@media only screen and (min-width: 2000px) {
  /* for sumo sized (mac) screens */
}

/* MOBILES */
@media only screen and (min-width: 50px) and (max-width: 900px) {
  .formStandardLogin
  {
    width:20rem;
  }
  input#userName
  {
    width:19rem !important;
  }
  #SideBarMenuLoginDiv {
    display: inline-block;
    width: auto !important;
    height: auto !important;

    /* height: 34.2rem; */
    background-color: #ffffff;
    margin-left: -0.5rem;
    /*margin-top: -15%;*/
    margin-top: 15%; 
    position: relative;
    z-index: 2;
  }

  #ErrorMessageDivMaster{
    display: none;
  }
  /* .SideBarMenuDivLogin {
    display: inline-flex;
  } */
  .HelpPopup {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(3, 41, 78, 0.56);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    z-index: 4;
    justify-content: center;
    align-content: center;
    display:flex;
    flex-direction: row;
    /* background-color: rgba(0, 0, 0, 0.5); */
  }
  .LogInPopup {
    border: none;
    box-shadow: none;
    position: relative;
    max-width: 100% !important;
    width: 100% !important;
    background-color: white;
    /*margin-top: 2rem;*/
    margin-left: 0%;
    z-index: 1;
    top: 0rem;
    left: 0rem;
    right: 0rem;
    bottom: 0rem;
    margin: 0.5rem 0 0 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    /* top: 6rem;
    left: 0rem;
    right: 0rem;
    bottom: 0rem;
    margin: 0 0 0 0; */
    /* display: inline-flex;
    justify-content: left;
    align-content: left;
    justify-items: left; */
    /* z-index: 2; */
    /* margin-left: 1.75rem;
    margin-right: 1.75rem; */
    /*height: 31.2rem !important;*/
    /* height: 91.5% !important;
    max-width: 100% !important;
    width: 100% !important;
    background-color: rgba(0, 0, 0, 0.5); */
  }

  .formRegister
  {
    width:20rem;
  }

  .LogInPopupInner {
    /* position: relative;
    width: 100%;
    height: auto;
    left: 21.3%;
    top: 10%;

    /* padding: 5px; */

    background-color: #ffffff;
    /* border-top: 0.25rem #629ac4 solid; */
    border: none;

    /* animation-name: LogInPopupInnerAnimation;
    animation-duration: 0.75s; */
    /*width: 99% !important;*/
    max-height: 100% !important;
    padding-bottom: 0rem;
    height: auto;
    /* box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16); */
    background-color: #ffffff;
    /* border-top: 0.177rem #e25824 solid !important; */
    border: none;
    box-shadow: none;
    position: relative;
    max-width: 100% !important;
    /*width: 20rem !important;*/
    z-index: 1;
    /*margin-top:2rem;*/
  }

  #LoginContent {
    visibility: hidden;
    width: 100%;
  }

  .LogInTitle {
    /*display: inline-flex;
    align-items: center;
    margin-top: -15rem;*/
    /* width: 19.625rem;
      height: 1.813rem; */
    font-size: 1.5rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: normal;
    text-align: center;
    color: #629ac4;
  }

  #smallCloseBtnDivOneTimeLogin {
    display: block !important;
    visibility: none;
    opacity: 0%;
  }

  #closeBtn {
    display: block !important;
    visibility: none;
    opacity: 0%;
  }
  /*.LogInTitle {
  }*/
  .formLogin
  {
    width:19rem;
    height:auto;
  }
  .FormButtonsDivLogin
  {
    width:17.2rem;
    display: inline-flex;
    position: relative;
    justify-content: flex-end !important;
    align-content: flex-end !important;
    place-content: flex-end !important;
    justify-content: flex-end !important;
    align-content: flex-end !important;
    place-content: flex-end !important;
    margin: 0.5rem 0rem 0.5rem 0rem !important;
  }

  .LogInTitle4 {
    align-items: center;
    display: block;
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
    font-size: 0.85rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #252930;
    margin-left:0.5rem;
  }
  input#fullNameCompany,input#nationalIDRegNum,input#email,input#mobileInput,input#password,input#confirmPassword
  {
    width:19rem !important;
  }
  .mobileInput
  {
    width:19rem !important;
  }
  .FormButtonsDivRegister {
    display: inline-flex;
    width: 19rem !important;
    position: relative;
    justify-content: flex-end !important;
    align-content: flex-end !important;
    place-content: flex-end !important;
    justify-content: flex-end !important;
    align-content: flex-end !important;
    place-content: flex-end !important;
    margin: 0.5rem 0rem 0.5rem 0rem !important;
  }
  .FormButtonsDiv
  {
    width:19rem !important;
    padding-right:0rem !important;  
  }
  .FormButtonsVerificationDiv
  {
    width:14rem !important;
    padding-right: 0rem !important;
  }
  .LogInTitle2 {
    align-items: center;
    margin-top: 1rem;
    font-size: 1rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.19;
    letter-spacing: normal;
    text-align: center;
    color: #252930;
  }
  .HelpPopupInner {
    position: absolute;
    width: 22rem;
    height: 43em;
    top: 0.2rem;
    background-color: #ffffff;
    z-index: 4;
  }
  .HelpParagraph {
    margin-top: 0.5rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    font-size: 0.85rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #252930;
  }
  .HelpList {
    display: block;
    align-items: left;
    margin-top: 0.1rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    font-size: 0.85rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #252930;
  }
  /* .closeBtn {
    background-image: url("components/images/closeBtn.svg");
    background-repeat: no-repeat;
    background-color: #629ac4;
    width: 0.643rem;
    height: 0.643rem;
    object-fit: contain;
    border: none;
    margin-right: 0.8rem;
    margin-top: 0.6rem !important;
    margin-left: 0.8rem;
  } */
}
