@media only screen and (min-width: 1000px){
  #AppTrackWrapper {
    display: flex;
    justify-items: center;
    margin-left: 1.75rem;
    /*margin-top: 1.479rem;
    margin-bottom: 1.479rem;*/
    margin-right: 1.75rem;
    /*height: 31.2rem;*/
    /*height:91.5%;*/
    min-height: 91.5%;
    max-width: 100% !important;
    background-color: #f3f7fa;
    }
    .ApplicationTrakingTitle {
      /* width: 9.938rem;
      height: 1.813rem; */
      font-size: 1.5rem;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.21;
      letter-spacing: normal;
      text-align: left;
      color: #629ac4;
      margin-top: 2.757rem;
      margin-left: 3.942rem;
      display: block;
    }
    .AppTrackFormDesc
    {
      display: block;
      font-size: 0.85rem;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 2.19;
      letter-spacing: normal;
      text-align: left;

      margin-top: 1rem;
      /*margin-left: 2rem;*/
      text-align: justify;
      color: #252930;
    }
    #AppTrackSearchFormDiv {
      display: flex;
      justify-items: flex-start;
      place-items: flex-start;
      place-content: flex-start;
      margin-top: 0rem;
      /*padding-left: 2.5rem;*/
      /*width: 82%;*/
    }
    .formAppTrack
    {
      width:100%;
      height:auto;
      margin-left:0rem;
      margin-top:0rem;
      /*padding-left:0.5rem;
      padding-right:0.5rem;*/
    }
    #AppTrackPaymentButttonsLocateDNDiv
    {
      margin-top:1rem;
      margin-left:0rem !important;
      float:right;
    }
}

@media only screen and (min-width: 50px) and (max-width: 900px) {
  #workArea
  {
    width:100% !important;
  }
  #AppTrackWrapper {
    display: flex;
    justify-items: center;
    min-height: 91.5%;
    max-width: 100% !important;
    width:19rem;
  }
  #ApplicationTrakingPage{
    width:19rem !important;
  }
  .ApplicationTrakingTitle {
    /* width: 9.938rem;
    height: 1.813rem; */
    font-size: 1.5rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: normal;
    text-align: left;
    color: #629ac4;
    display: block;
  }
  #Content
  {
    width:20rem;
    margin-left:1rem;
  }
  .LandRegistryStages
  {
    display:flex;
    justify-content: center;
    width:20rem;
  }
  .AppTrackFormDesc
  {
    display: block;
    font-size: 0.85rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.19;
    letter-spacing: normal;
    text-align: left;
    margin-top: 1rem;
    color: #252930;
  }

  #AppTrackSearchFormDiv {
    display: flex;
    justify-items: flex-start;
    place-items: flex-start;
    place-content: flex-start;
    margin-top: 0rem;
    width: 100%;
  }
  .formAppTrack
  {
    width:100%;
    height:auto;
    margin-left:0rem;
    margin-top:0rem;
    padding-left:0rem;
    padding-right:0rem;
  }
  #AppTrackPaymentButttonsLocateDNDiv
  {
    margin-top:1rem;
    margin-left:0rem !important;
    width:95%;
    display:flex;
    flex-direction: row-reverse;
  }
  .col
  {
    margin-right:1.5rem;
  }
  input#AppNumber{
    width:17rem !important;
  }
}

#AppTrackingMain
{
  height:100%;
}

    @media only screen and (min-width:1400px) {
      #ApplicationTrakingPage {
        /*width: 99% !important;*/
        /*max-height: 100% !important;*/
        padding-bottom: 3rem;
        /*height: 31rem !important;*/
        /*height:100% !important;*/
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
        background-color: #ffffff;
        border-top: 0.177rem #71a543 solid;
        /*position: absolute;*/
        width:99.6%;
        /*width:63.2rem;*/
        animation-name: pageAnimationApplicationTraking;
        animation-duration: 0.75s;
        }
    }

    /*.AppTrackScrollbar{
      overflow-y: auto;
      height: 13.5rem;
    }
    div.AppTrackScrollbar::-webkit-scrollbar{
      width:5px;
      box-shadow: inset 0 0 5px grey;
      border-radius: 5px;
    }*/

    @media only screen and (width:1366px) {
      #ApplicationTrakingPage {
        width: 63.2rem !important;
        /*max-height: 100% !important;*/
        padding-bottom: 3rem;
        min-height: 31rem !important;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
        background-color: #ffffff;
        border-top: 0.177rem #71a543 solid;
        position: absolute;
        animation-name: pageAnimationApplicationTraking;
        animation-duration: 0.75s;
        }
    }
    .justify-content-left
    {
      justify-content: left !important;
    }
    .AppTrackFormDescBold
    {
      font-weight: bold;
    }
    .FirstSubSectionAppTrackTitleRow {
      /*display: block;*/
      display: flex !important;
      /* width: 100%; */
      background-color: #ffffff;
      color: #2c638d;
      padding-left: 0rem !important;
      font-size: 1rem;
      border-bottom: 0.02rem solid lightgrey;
      height: 2.218rem;
      vertical-align: middle !important;
      margin-top: 0rem;
    }
    .FirstSubSectionAppTrackDataRow {
      display: flex !important;
      background-color: #ffffff;
      color: #000000;
      padding-left: 0rem !important;
      font-size: 0.85rem;
      border-bottom: 0.01rem solid lightgrey;
      height: 2.218rem;
      vertical-align: middle !important;
      margin-top: 0rem;
      cursor: pointer;
    }
    .col2
    {
      flex-basis: 0;
      flex-grow:2;
      min-width: 0;
      max-width: 100%;
    }
    @keyframes pageAnimationApplicationTraking{
      from{width:1px;height:1px;top:350px;left:288px}
      to{width:63.2rem;height:31rem;top:103px;left:323px}
    }  