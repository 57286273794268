/*********************************************** NEW ***********************************************************************/
/* default styles here for older browsers. 
       I tend to go for a 600px - 960px width max but using percentages
    */

#Join-us {
  /* width: 2.688rem;
        height: 1.438rem; */
  /* font-family: Titillium Web; */
  font-size: 1rem !important;
  line-height: 1.188;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  text-align: left;
  color: #629ac4;
  /* align-items: right;
      align-items: right; */
  vertical-align: middle;
  /* justify-content: right; */
  display: block;
  /* margin-left: 17rem; */
  word-wrap: none;
  padding-bottom: 0.3rem;
  min-width: 2.688rem;
  /* position: sticky; */
}

.Join-usLink {
  font-style: normal;
  line-height: 1.6;
  font-size: 1rem;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #629ac4;
  position: stick;
  word-wrap: none;
  display: inline-block;
}

#Ministry-of-Lands-and-Natural-Resources-Online-Services {
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.188;
  letter-spacing: normal;
  text-align: left;
  color: #252930;
  align-items: center;
  align-items: center;
  vertical-align: middle;
  justify-content: center;
  width: 50%;
  padding-left:0.25rem;
}

.separatorDiv {
  color: #629ac4;
}
#rigthMenu {
  width: 50% !important;
  min-width: 200px !important;
  display: inline-flex;
  position: flex-end;
  align-items: flex-end;
  align-content: flex-end;
  vertical-align: middle;
  place-items: flex-end;
  place-content: flex-end;
  word-wrap: none;
  float: inline-end;
}

#RightMenuUser {
  width: 50% !important;
  min-width: 200px !important;
  display: inline-flex;
  position: flex-end;
  justify-items: right;
  align-items: right;
  align-content: right;
  vertical-align: middle;
  place-items: right;
  place-content: right;
  word-wrap: none;
  position: relative;
  padding-left: 10.5rem;
  margin-top: 0.75rem;
}
#RightMenuUserControls {
  position: absolute;
  right: 0rem;
  margin-top: -1.9rem;
}

#SignIn {
  /* width: 5.5rem !important; */
  width: 6rem !important;
  height: 2.128rem !important;
  background-color: #629ac4;
  color: #ffffff;
  margin-left: 2rem;
  place-content: center;
  align-items: center;
  vertical-align: middle;
  justify-content: center;
  display: inline-flex;
  float: right;
  word-wrap: none;
}
.SignInLink {
  font-size: 1rem !important;
  position: relative;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.77;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
  text-align: center;
  align-content: center;
  vertical-align: middle;
  word-wrap: none;
  text-decoration: none;
  display: inline-block;
  word-wrap: none;
  width: 100%;
  height: 2.128rem;
  padding-left: 1.2rem;
  padding-right: 1rem;
  padding-bottom: 0.2rem;
  padding-top: 0.2rem;
}

.SignIn a:hover,
.SignIn a:active,
.SignIn span:hover,
.SignIn span:active,
.SignIn a span:hover,
.SignIn a span:active {
  color: #ffffff;
  text-decoration: none !important;
}

#HelloUserDiv {
  /* font-size: 1.25rem;
  font-weight: 600; */
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: normal;
  text-align: left;
  color: black;
  align-items: center;
  align-items: center;
  vertical-align: middle;
  justify-content: center;
  display: inline-flex;
  /* float: rigth; */
  margin-left: 0rem;
  height: 3.5rem;

  font-size: 0.85rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54;
  letter-spacing: normal;
  text-align: right;
  color: #252930;
}

.HelloUser {
  /* width: 6.563rem; */
  height: 3.5rem;
  font-size: 0.85rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  /* line-height: 1.54; */
  letter-spacing: normal;
  text-align: right;
  color: #252930;
  padding-top: 0.9rem;
}

.userProfileDiv {
  display: inline-flex;
  margin-left: 1em;
  width: 3rem;
  height: 3rem;
  background-color: #629ac4;
  -moz-border-radius: 1.5em;
  -webkit-border-radius: 1.5em;
  border-radius: 1.5em;
  margin-top: 0rem;
  margin-bottom: 0.85rem;
  /* align-items: center;
  align-content: center;
  vertical-align: middle;
  justify-content: center;
  align-content: center;
  place-content: center;
  justify-content: center;
  vertical-align: middle;
  place-items: center; */
  color: white;
  padding-top: 0.85rem;
  padding-left: 0.85rem;
}

/* .userProfileDiv {
  display: inline-block;
  margin-left: 1em;
  width: 3.5rem;
  height: 3.5rem;
  background-color: #629ac4;
  -moz-border-radius: 2em;
  -webkit-border-radius: 2em;
  border-radius: 2em;
  margin-top: 0;
} */

.userProfilePic {
  align-content: center;
  vertical-align: middle;
  width: 1.183rem;
  height: 1.446rem;
  object-fit: contain;
}

/*DESKTOPS*/
/* (1920x1080) Full HD Display */
@media screen and (max-width: 1920px) {
}
/* (1366x768) WXGA Display */
@media screen and (max-width: 1366px) {
}

@media only screen and (min-width: 960px) {
  /* styles for browsers larger than 960px; */
  .MinistryFullTitle {
    display: inline-block;
  }
  .MinistryShortTitle {
    display: none;
  }
}
@media only screen and (min-width: 1440px) {
  /* styles for browsers larger than 1440px; */
  .MinistryFullTitle {
    display: inline-block;
  }
  .MinistryShortTitle {
    display: none;
  }
}
@media only screen and (min-width: 2000px) {
  /* for sumo sized (mac) screens */
}

@media only screen and (min-width: 1000px) {
  #MainNavDiv {
    width: 100%;
    width: 100%;
    background-color: #f3f7fa;
    display: inline-flex;
    z-index: 2;
    align-items: center;
    padding-left: 1.75rem;
    padding-right: 2rem;
  }
}

/* MOBILES */
@media only screen and (min-width: 50px) and (max-width: 900px) {
  #rigthMenu {
    width: 75% !important;

    display: inline-flex;
    /* align-items: left;
    place-items: left;
    place-content: left;
    place-content: left; */
    /* align-items: flex-start;
    align-content: flex-start;
    vertical-align: middle;
    place-items: flex-start;
    place-content: flex-start; */
    /* 
    
    word-wrap: none; */
  }

  /* #rigthMenu {
    width: 50% !important;
    min-width: 200px !important;
    display: inline-flex;
    position: flex-end;
    align-items: flex-end;
    align-content: flex-end;
    vertical-align: middle;
    place-items: flex-end;
    place-content: flex-end;
    word-wrap: none;
    float: inline-end;
  } */

  #MainNavDiv {
    width: 100%;
    /* background-color: #f3f7fa; */
    background-color: white;
    display: inline-flex;
    z-index: 1;
    align-items: center;
    padding-left: 0rem;
    padding-left: 0rem !important;

    padding-right: 0rem;
  }

  /* styles for mobile browsers smaller than 480px; (iPhone) */
  .Republic-of-Zambia {
    display: none;
  }

  .MinistryFullTitle {
    display: none;
  }
  .MinistryShortTitle {
    display: inline-block;
    text-align: center;
    font-weight: 600;
    margin-left:0rem !important;
  }

  #Join-us {
    font-size: 0.85rem !important;
    /* line-height: 1.188; */
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    text-align: left;
    color: #629ac4;

    display: block;
    word-wrap: none;
    padding-bottom: 0.4rem;
    margin-right: 0%;
    min-width: 1rem;
  }

  .Join-usLink {
    font-style: normal;
    line-height: 1.6;
    font-size: 0.85rem;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #629ac4;
    position: stick;
    word-wrap: none;
    display: inline-block;
  }

  #SignIn {
    /* width: 5.5rem !important; */
    width: 5rem !important;
    height: 2rem !important;
    background-color: #629ac4;
    color: #ffffff;
    margin-left: 1rem;
    place-content: center;
    align-items: center;
    vertical-align: middle;
    justify-content: center;
    display: inline;
    margin-right: 20%;
    /* margin-right: 1rem; */
    /* float: right; */
    word-wrap: none;
  }
  .SignInLink {
    font-size: 0.85rem !important;
    position: relative;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.77;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
    text-align: center;
    align-content: center;
    vertical-align: middle;
    word-wrap: none;
    text-decoration: none;
    display: inline-block;
    word-wrap: none;
    /* width: 100%; */
    height: 2rem;
    padding-left: 1rem;
    padding-right: 0.8rem;
    padding-bottom: 0.2rem;
    padding-top: 0.2rem;
  }

  #RightMenuUser {
    width: 75% !important;
    /* min-width: 200px !important; */
    display: inline-flex;
    /* position: flex-end; */
    justify-items: center;
    align-items: center;
    align-content: center;
    vertical-align: middle;
    place-items: center;
    place-content: center;
    word-wrap: none;
    position: relative;
    padding-left: 0rem;
    margin-top: 0.75rem;
    padding-left: 5rem;
    margin-top: 0.5rem;
  }
  #RightMenuUserControls {
    /* position: absolute; */
    /* position: fixed; */
    /* right: 0;
    left: 30%; */
    /* margin-top: -1.9rem; */
    margin-top: 0.5rem;
    padding-left: 10%;
    width: 100%;
  }

  #HelloUserDiv {
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: black;
    align-items: flex-start;
    align-items: flex-start;
    vertical-align: middle;
    justify-content: flex-start;
    display: inline-flex;
    /* float: rigth; */
    margin-left: 0rem;
    height: 3.5rem;

    font-size: 0.75rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    /* line-height: 1.54; */
    line-height: 1.21;
    letter-spacing: normal;
    text-align: right;
    color: #252930;
  }

  .HelloUser {
    /* width: 6.563rem; */
    width: 5.5rem;
    /* height: 5.5rem; */
    font-size: 0.85rem;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: normal;
    text-align: left;
    color: #252930;
    padding-top: 0rem;
    word-wrap: wrap;
  }

  .userProfileDiv {
    display: inline-flex;
    margin-left: 1rem;
    width: 2.4rem;
    height: 2.4rem;
    background-color: #629ac4;
    -moz-border-radius: 1.2em;
    -webkit-border-radius: 1.2em;
    border-radius: 1.2em;
    margin-top: 0rem;
    /* margin-bottom: 0.85rem; */

    color: white;
    /* padding-top: 0.85rem;
    padding-left: 0.85rem;
    margin-bottom: 0.1rem; */

    padding-top: 0.85rem;
    padding-left: 0.4rem;
    margin-bottom: 0rem;
  }

  .userProfilePic {
    align-content: center;
    vertical-align: middle;
    /* width: 1.183rem;
    height: 1.446rem; */
    width: 1.5rem;
    height: 1.5rem;
    object-fit: contain;
    margin-top: -0.5rem;
  }
}

/* for all mobile/cellphone widths */
@media only screen and (min-width: 50px) and (max-width: 767px) and (orientation: portrait) {
  /* //Put your CSS here for 200px to 767px width devices (cover all mobile portrait width // */
}

/* for all mobile/cellphone widths */
@media only screen and (min-width: 50px) and (max-width: 767px) and (orientation: landscape) {
  /* //Put your CSS here for 200px to 767px width devices (cover all mobile portrait width // */
}

/* TABLETS*/
@media only screen and (min-width: 860px) and (max-width: 1024px) {
  /* //Put your CSS here for 768px to 1024px width devices(covers all iPad portrait width //
    */
  .MinistryFullTitle {
    display: inline-block;
  }

  #Ministry-of-Lands-and-Natural-Resources-Online-Services {
    font-size: 1rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.188;
    letter-spacing: normal;
    text-align: left;
    color: #252930;
    align-items: left;
    justify-content: center;
    width: 70%;
    padding-left: 0rem;
  }


  .MinistryShortTitle {
    display: none;
  }
  .navbar-expand-lg {
    flex-flow: row nowrap !important;
    justify-content: flex-start !important;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  #Join-us {
    font-size: 1rem !important;
    line-height: 1.188;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    text-align: left;
    color: #629ac4;

    display: block;
    word-wrap: none;
    padding-bottom: 0.5rem;
    min-width: 2rem;
  }

  .Join-usLink {
    font-style: normal;
    line-height: 1.6;
    font-size: 1rem;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #629ac4;
    position: stick;
    word-wrap: none;
    display: inline-block;
  }

  #MainNavDiv {
    width: 100%;
    background-color: #f3f7fa; 
    display: inline-flex;
    z-index: 2;
    align-items: center;
    padding-left: 1rem;
    padding-right: 2rem;
  }
}

/* different techniques for iPad screening */
@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
  /* For portrait layouts only */
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: landscape) {
  /* For landscape layouts only */
}

/*********************************************** NEW ***********************************************************************/

/*********************************************** NEW ***********************************************************************/

/* default styles here for older browsers. 
       I tend to go for a 600px - 960px width max but using percentages
    */

/* MOBILES */
@media only screen and (min-width: 50px) and (max-width: 767px) {
  /* styles for mobile browsers smaller than 480px; (iPhone) */
  .Welcome_Title {
    display: none;
  }
}

/* for all mobile/cellphone widths */
@media only screen and (min-width: 50px) and (max-width: 767px) and (orientation: portrait) {
  /* //Put your CSS here for 200px to 767px width devices (cover all mobile portrait width // */
}

/* for all mobile/cellphone widths */
@media only screen and (min-width: 50px) and (max-width: 767px) and (orientation: landscape) {
  /* //Put your CSS here for 200px to 767px width devices (cover all mobile portrait width // */
}

/* TABLETS*/
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  /* //Put your CSS here for 768px to 1024px width devices(covers all iPad portrait width //
    */
}

/* different techniques for iPad screening */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  /* For portrait layouts only */
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  /* For landscape layouts only */
}

/*DESKTOPS*/
/* (1920x1080) Full HD Display */
@media screen and (min-device-width: 1366px) and (max-device-width: 1999px) {
  /* in sidebar.jsx page */
}
/* (1366x768) WXGA Display */
@media screen and (min-device-width: 1025px) and (max-device-width: 1365px) {
}

@media only screen and (min-width: 767px) and (max-width: 959px) {
}
@media only screen and (min-width: 960px) and (max-width: 1024px) {
  /* styles for browsers larger than 1440px; */
}
@media only screen and (min-width: 2000px) {
  /* for sumo sized (mac) screens */
}
