@media only screen and (min-width: 1400px) {
  .PersonalZoneWrapper {
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    /* text-align: center; */
    background-color: #ffffff;
    border-top: 0.177rem #279762 solid;
    min-height: 32rem !important;
    float: rigth;
    margin-left: 1.75rem;
    /*margin-top: 1.479rem;
    margin-bottom: 1.479rem;*/
    margin-right: 1.75rem;
    /* width: 99%; */
    /* width: 93.1%; */
    /* height: 100%; */
    height: 100%;
    padding-bottom: 5rem;
    /*width: 63.2rem;*/
    /*position: absolute;*/
    width: 96%;
    animation-name: pageAnimationPersonalZone;
    animation-duration: 0.75s;
  }
}

#PersonalZoneMain {
  display: inline-flexbox;
  width: 100% !important;
  height: 100% !important;
  visibility: hidden;
}

.SectionDiv {
  width: 100%;
  height: 2.218rem;
  display: inline-flex;
  background-color: #f5f5f5;
  place-content: start-flex;
  place-items: start-flex;
  vertical-align: center;
  align-items: center;
  align-content: center;
}

.SectionTitle {
  width: 17.318rem;
  /* width: auto; */
  /* height: 1.653rem; */
  font-size: 0.85rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 3;
  letter-spacing: normal;
  text-align: left;
  color: #629ac4;
  vertical-align: center;
}

.CloseSectionDiv {
  background-image: url("../components/images/downArrow.svg");
  background-repeat: no-repeat;
  width: 1.262rem;
  height: 0.765rem;
  object-fit: contain;
  border: none;
  margin: 1% !important;
  justify-content: center;
  align-content: center;
  vertical-align: center;
}

.OpenSectionDiv {
  background-image: url("../components/images/upArrow.svg");
  background-repeat: no-repeat;
  width: 1.262rem;
  height: 0.765rem;
  object-fit: contain;
  border: none;
  margin: 1% !important;
  justify-content: center;
  align-content: center;
  vertical-align: center;
}
.OpenSectionDiv:hover,
.CloseSectionDiv:hover {
  cursor: pointer !important;
  pointer-events: auto;
}

.spaceDiv {
  width: 100%;
  height: 1rem !important;
}

.FirstSubSectionTitleRow {
  /*display: block;*/
  display: flex !important;
  /* width: 100%; */
  background-color: #ffffff;
  color: #2c638d;
  padding-left: 2.438rem !important;
  font-size: 1rem;
  border-bottom: 0.02rem solid lightgrey;
  height: 2.3rem;
  vertical-align: middle !important;
  margin-top: 0rem;
}

.FirstSubSectionTitle {
  font-size: 0.85rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #2c638d;
  /* width:auto !important; */
}

.FirstSubSectionDataRow {
  display: flex !important;
  background-color: #ffffff;
  color: #000000;
  padding-left: 2.438rem !important;
  font-size: 0.85rem;
  border-bottom: 0.01rem solid lightgrey;
  height: 2.3rem;
  vertical-align: middle !important;
  margin-top: 0rem;
  cursor: auto;
}

.FirstSubSectionData {
  font-size: 0.85rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  /* line-height: 4.5; */
  letter-spacing: normal;
  text-align: left;
  color: #000000;
  /*  width:auto !important; */
}

.OpenSubSectionDiv {
  width: 1rem !important;
  /* width: 1rem;
height: 1.375rem;
font-family: Verdana; */
  font-size: 0.85rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  /* line-height: 4; */
  letter-spacing: normal;
  text-align: left;
  color: #629ac4;
  padding-right: 0.5rem !important;
  cursor: pointer;
}

.CloseSubSectionDiv {
  width: 1rem !important;
  /* width: 1rem;
height: 1.375rem;
font-family: Verdana; */
  font-size: 0.85rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  /* line-height: 4; */
  letter-spacing: normal;
  text-align: left;
  color: #629ac4;
  padding-right: 0.2rem !important;
  cursor: pointer;
}

.SecondSubSectionRow {
  display: flex !important;
  width: 100%;
  background-color: #ffffff;
  color: #000000;

  padding-left: 3.5rem !important;
  font-size: 0.8rem;
  border-bottom: 0.01rem dashed lightgrey;
  height: 2.3rem;
  vertical-align: middle !important;
  margin-top: 0rem;
  cursor: auto;
  width: 100% !important;
}

.SecondSubSectionTitle {
  background-color: #ffffff;
  color: #000000;
  font-size: 0.8rem;
  border-bottom: 0.01rem dashed lightgrey;
  height: 2.3rem;
  vertical-align: middle !important;
  margin-top: 0rem;
  width: auto !important;
  text-align: left;
}
.SecondSubSectionTitleRight {
  background-color: #ffffff;
  color: #000000;
  font-size: 0.8rem;
  border-bottom: 0.01rem dashed lightgrey;
  height: 2.3rem;
  vertical-align: middle !important;
  margin-top: 0rem;
  width: auto !important;
  text-align: right;
  margin-right: 2rem;
}

.SecondSubSectionData {
  background-color: #ffffff;
  color: #000000;
  font-size: 0.8rem;
  border-bottom: 0.01rem dashed lightgrey;
  height: 2.3rem !important;
  vertical-align: middle !important;
  margin-top: 0rem;
  margin-bottom: 0rem;
  text-align: left;
  width: 2rem !important;
  /* width: 2rem !important; */
}

.SecondSubSectionRightData {
  background-color: #ffffff;
  color: #000000;
  font-size: 0.8rem;
  border-bottom: 0.01rem dashed lightgrey;
  height: 2.3rem !important;
  vertical-align: middle !important;
  margin-top: 0rem;

  text-align: right;
  margin-right: 2rem;
  /* width: 2rem !important; */
}

.DebtSubSectionData {
  color: #ff0000;
}
.GoPayment {
  float: right;
  margin-right: -2.5rem;
}
.invisibleClass {
  display: none;
}

.DocumentImage {
  width: 1.218rem;
  height: 1.488rem;
  object-fit: contain;
}
.DocumentImagePath {
  width: 1.218rem;
  height: 1.488rem;
  background-color: #629ac4;
}
.documentImageSpan {
  justify-content: center;
  align-content: center;
  justify-self: center;
  padding-left: 20%;
}

@keyframes pageAnimationPersonalZone {
  from {
    width: 1px;
    height: 1px;
    top: 300px;
    left: 288px;
  }
  to {
    width: 62.3rem;
    height: auto;
    top: 78px;
    left: 290px;
  }
}

@media only screen and (width: 1366px) {
  .PersonalZoneWrapper {
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    /* text-align: center; */
    background-color: #ffffff;
    border-top: 0.177rem #279762 solid;
    min-height: 32rem !important;
    float: rigth;
    margin-left: 1.75rem;
    /*margin-top: 1.479rem;
    margin-bottom: 1.479rem;*/
    margin-right: 1.75rem;
    height: auto;
    padding-bottom: 5rem;
    width: 62.3rem;
    position: absolute;
    animation-name: pageAnimationPersonalZone;
    animation-duration: 0.75s;
  }
}

@media only screen and (min-width: 1000px){
  .PersonalZoneTitle {
    font-size: 1.5rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: normal;
    text-align: left;
    color: #629ac4;
    margin-top: 2.757rem;
    margin-left: 3.942rem;
    display: block;
  }

  .PersonalDataTitle {
    width: 100%;
    margin-left: 3.942rem;
    margin-top: 1.14rem;
    padding-left: 0rem;
    padding-right: 26rem;
    /* align-items: center; */
    margin-top: 1.14rem;
    font-size: 0.85rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.19;
    letter-spacing: normal;
    /* text-align: center; */
    color: #252930;
  }
  #PersonalDataForm {
    display: grid;
    justify-content: left;
    align-content: center;
    align-items: center;
    margin-top: 0rem;
    margin-left: 3.942rem;
    width: 100%;
    padding-left:0rem !important;
    padding-right:0rem !important;
  }
  .FirstSubSectionTitleRight {
    font-size: 0.85rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: right;
    color: #2c638d;
    /* width:auto !important; */
  }
  .FirstSubSectionRightData {
    font-size: 0.85rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    /* line-height: 4.5; */
    letter-spacing: normal;
    text-align: right;
    color: #000000;
  }
}

/* MOBILES */
@media only screen and (min-width: 50px) and (max-width: 900px) {
  .personalDataFieldset
  {
    margin-left:-0.5rem;
    width:19rem;
  }
  .PersonalZoneTitle {
    font-size: 1.5rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: normal;
    text-align: left;
    color: #629ac4;
    display: inline-flexbox;
  }
  .PersonalDataTitle {
    width: 19rem;
    /* margin-left: 3.942rem;
    margin-top: 1.14rem; */
    margin-left: 0rem;
    margin-right: 0rem;
    margin-top: 0rem;

    /*padding-left: 1rem;
    padding-right: 1rem;*/
    /* align-items: center; */
    margin-top: 1.14rem;
    font-size: 0.85rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.19;
    letter-spacing: normal;
    /* text-align: center; */
    color: #252930;
  }

  #PersonalDataForm {
    display: grid;
    justify-content: left;
    align-content: left;
    align-items: left;
    place-content: left;
    place-items: left;
    margin-top: -1rem;
    /*margin-left: 0.2rem;*/
    width: 100% !important;
  }

  #NameLabel {
    display: none;
  }
  #NIDLabel {
    display: none;
  }
  #EmailLabel {
    display: none;
  }
  #MobileLabel {
    display: none;
  }
  #PWDLabel {
    display: none;
  }
  #ConfirmPWDLabel {
    display: none;
  }
  #InputWrapperDiv {
    width: 10% !important;
    display: inline-flex;
  }

  .FirstSubSectionTitleRow {
    /*display: block;*/
    display: inline-flexbox !important;
    width: 100% !important;
    background-color: #ffffff;
    color: #2c638d;
    padding-left: 0rem !important;
    padding-right: 0rem !important;
    font-size: 0.8rem;
    border-bottom: 0.02rem solid lightgrey;
    height: auto;
    vertical-align: middle !important;
    margin-top: 0rem;
  }

  .FirstSubSectionTitle {
    font-size: 0.85rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #2c638d;
    width: auto;
    height: auto;
    /* width:auto !important; */
  }

  .FirstSubSectionTitleRight {
    font-size: 0.8rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: right;
    color: #2c638d;
    width: auto !important;
    height: auto !important;
    /* width:auto !important; */
  }
  /* nn */
  .FirstSubSectionDataRow {
    display: inline-flex !important;
    background-color: #ffffff;
    color: #000000;
    padding-left: 0rem !important;
    font-size: 0.7rem;
    border-bottom: 0.01rem solid lightgrey;
    height: auto !important;
    width: 100% !important;
    vertical-align: top !important;
    margin-top: 0rem;
    cursor: auto;
  }

  .FirstSubSectionData {
    font-size: 0.7rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    /* line-height: 4.5; */
    letter-spacing: normal;
    text-align: left;
    color: #000000;
    /*  width:auto !important; */
  }

  .FirstSubSectionRightData {
    font-size: 0.7rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    /* line-height: 4.5; */
    letter-spacing: normal;
    text-align: right;
    color: #000000;
    margin-left:1rem;
  }

  .OpenSubSectionDiv {
    width: auto !important;
    height: auto !important;
    /* width: 1rem;
  height: 1.375rem;
  font-family: Verdana; */
    font-size: 0.7rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    /* line-height: 4; */
    letter-spacing: normal;
    text-align: left;
    color: #629ac4;
    padding-right: 0rem !important;

    cursor: pointer;
  }

  .CloseSubSectionDiv {
    width: auto !important;
    height: auto !important;
    font-size: 0.7rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    /* line-height: 4; */
    letter-spacing: normal;
    text-align: left;
    color: #629ac4;
    padding-right: 0rem !important;
    cursor: pointer;
  }

  .SecondSubSectionRow {
    display: inline-flex !important;
    width: 100%;
    background-color: #ffffff;
    color: #000000;

    padding-left: 0rem !important;
    font-size: 0.7rem;
    border-bottom: 0.01rem dashed lightgrey;
    height: auto !important;
    vertical-align: middle !important;
    margin-top: 0rem;
    cursor: auto;
    width: 100% !important;
  }

  .SecondSubSectionTitle {
    background-color: #ffffff;
    color: #000000;
    font-size: 0.7rem;
    border-bottom: 0.01rem dashed lightgrey;
    height: auto !important
    ;
    vertical-align: middle !important;
    margin-top: 0rem;
    width: auto !important;
    text-align: left;
    width: 3rem !important;
  }
  .SecondSubSectionTitleRight {
    background-color: #ffffff;
    color: #000000;
    font-size: 0.7rem;
    border-bottom: 0.01rem dashed lightgrey;
    height: auto !important;
    vertical-align: middle !important;
    margin-top: 0rem;
    width: auto !important;
    height: auto !important;
    text-align: right;
    margin-right: 0.8rem;
    width: 2rem !important;
  }

  .SecondSubSectionData {
    background-color: #ffffff;
    color: #000000;
    font-size: 0.8rem;
    border-bottom: 0.01rem dashed lightgrey;
    height: 2.3rem !important;
    vertical-align: middle !important;
    margin-top: 0rem;
    margin-bottom: 0rem;
    text-align: left;
    width: 2rem !important;
    height: auto !important;
    /* width: 2rem !important; */
  }

  .SecondSubSectionRightData {
    background-color: #ffffff;
    color: #000000;
    font-size: 0.8rem;
    border-bottom: 0.01rem dashed lightgrey;

    height: auto !important;
    vertical-align: middle !important;
    margin-top: 0rem;
    width: 2rem !important;
    text-align: right;
    margin-right: 0rem;
    /* width: 2rem !important; */
  }
  .FormButtonsPersonalDiv
  {
    width:18.5rem !important;
  }
  .PersonalZoneWrapper
  {
    width:19rem;
  }
  .readonlyInput{
    width: 19rem !important;
  }
  input
  {
    width:19rem !important;
  }
  .FirstSubSectionTitleRight {
    font-size: 0.85rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: right;
    color: #2c638d;
    margin-left:1rem;
    /* width:auto !important; */
  }
  .MyPropertiesMobileDiv
  {
    display:inline;
    float: right;
  }
  .MyPropertiesMobileRow
  {
    width:18rem;
    height:2rem;
  }
  .MyPropertiesMobileColTitle
  {
    display: inline;
    float:left;
  }
  .MyPropertiesMobileColData
  {
    display: inline;
    float:right;
    margin-top:0.25rem;
  }
  .MyPropertiesMobileInnerColTitle
  {
    display:inline;
    float:left;
    padding-left:0.5rem;
  }
  .MyPropertiesMobileInnerColData
  {
    display:inline;
    float:right;
    margin-top:0.25rem;
    padding-right:0.5rem;
  }
  .GoPaymentMobile
  {
    float:right;
    margin-right:0rem !important;
    margin-left: 0.5rem !important;
  }
  .FirstSubSectionDataMobileRow
  {
    border-bottom:0.01rem solid lightgrey;
    margin-bottom:0.5rem;
  }
  .MyPropertyMobileDiv
  {
    display:inline;
    float:left;
    position:relative;
    height:7.2rem;
    width:1rem;
  }
}

/* for all mobile/cellphone widths */
@media only screen and (min-width: 50px) and (max-width: 900px) and (orientation: portrait) {
  /* //Put your CSS here for 200px to 767px width devices (cover all mobile portrait width // */
}

/* for all mobile/cellphone widths */
@media only screen and (min-width: 50px) and (max-width: 900px) and (orientation: landscape) {
  /* //Put your CSS here for 200px to 767px width devices (cover all mobile portrait width // */
}

/* TABLETS*/
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  /* //Put your CSS here for 768px to 1024px width devices(covers all iPad portrait width //
    */
}

/* different techniques for iPad screening */
@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
  /* For portrait layouts only */
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: landscape) {
  /* For landscape layouts only */
}

/*********************************************** NEW ***********************************************************************/
