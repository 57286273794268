.d-flex {
  margin-left: 2rem !important;
}
#CreditCardHolderName {
  width: 98%;
  background-color: #f5f5f5 !important;
  height: 2.21rem;
  border: hidden;
}
.CreditCardHolderName {
  width: 98%;
  background-color: #f5f5f5 !important;
  height: 2.21rem;
  border: hidden;
}
#CreditCardNumPart1,
#CreditCardNumPart2,
#CreditCardNumPart3,
#CreditCardNumPart4 {
  width: 90%;
  background-color: #dfe5ee !important;
  height: 2.21rem;
  border: hidden;
}
#ExpDateMM{
  width: 5rem !important;
  height: 2.21rem;
  text-align: left;
  border: hidden;
  background-color: #dfe5ee !important;
}
#ExpDateYY {
  width: 5rem !important;
  height: 2.21rem;
  text-align: left;
  border: hidden;
  background-color: #dfe5ee !important;
  margin-left:10px !important;
}

#CVCNumber {
  width: 100%;
  height: 2.21rem;
  text-align: center;
  border: hidden;
  background-color: #dfe5ee !important;
}
#EmailAddr {
  height: 2.21rem;
  text-align: center;
  border: hidden;
  background-color: #f5f5f5 !important;
}

#EmailConfirmAddr {
  height: 2.21rem;
  text-align: center;
  border: hidden;
  background-color: #dfe5ee !important;
}
.PaymentLabelWrapper {
  display: inline-block;
  margin-right: 0.5rem;
  height: 1rem;
  width:7rem;
  color: #42464c;
}
.PaymentLabel {
  font-size: 0.85rem;
  text-align: left;
  margin-right: 0.2857rem;
}
.CVCLabel {
  margin-right: 0.2857rem;
  font-size: 1.25rem;
  color: #707070;
  font-family: Roboto, Helvetica, sans-serif;
}
.ButtonStyle {
  font-family: Verdana, Geneva, sans-serif;
  float: right;
  background-color: #629ac4;
  height: 2.2131rem;
  color: #ffffff;
  border-radius: 0%;
  border-color: #6c757d;
  border: 1px solid transparent;
  font-size: 1.125rem;
  font-weight: 600;
}
.LandPaymentDetails {
  width: 100%;
  height: 6.188rem;
  border: solid 0.1875rem #dfe5ee;
  margin-left: 2rem;
  margin-top: 2.1rem;
}
/*.AdditionPayment
{
  margin-top:1.8rem !important;
}*/
#PaymentConfirmDetails
{
  width: 100%;
  height: 7rem;
  border: solid 3px #dfe5ee;
  /*margin-left: 1.827rem;
  margin-right: 1.827rem;*/
  justify-content: center;
  justify-items: center;
  align-content: center;
  display: block;
  margin-top:0.2rem;
}
.LandPaymentDetailsDiv {
  display: inline-flex;
  width: 20%;
  margin-left:4.5%;
  align-items: center;
  margin-top:0.67rem;
}
.LandPaymentDetail {
  font-family: Verdana, Geneva, sans-serif;
  margin-bottom: 0.2857rem;
  text-align: Center;
  font-size: 0.85rem;
  margin-left:1.2rem;
}
.LandPaymentDetailValue {
  font-size: 0.80rem;
  font-weight: normal;
  background-color: #f5f5f5;
  text-align: Center;
  padding: 0.5rem;
  padding-bottom: 1rem;
  margin-left: 1.5rem;
  margin-top:0.4rem;
  height: 2.218rem;
  font-style:normal;
}
.PaymentCreditCardWrapper {
  width: 34.85rem;
  margin-top: 2rem;
}
.PaymentDataCol
{
  width:120px !important;
  padding-right:0px !important;
  padding-left:0px !important;
}
.PaymentCreditCardField {
  margin-top: 0.9375rem;
  width: 34.85rem;
  margin-left: 2rem;
}
.PayerPhoneNumber,.PinCode,.BillingDetails {
  width: 100% !important;
  background-color: #dfe5ee !important;
}
.TextboxWrapper {
  display: inline-block;
  width: 24.9rem;
}
.TextboxDiv1 {
  display: inline-block;
  width: 25%;
  text-align: left;
}
.TextboxDiv2 {
  display: inline-block;
  width: 25%;
  text-align: center;
}
.TextboxDiv3 {
  display: inline-block;
  width: 25%;
  text-align: right;
}
.ExpirationDateWrapper {
  margin-top: 0.9375rem;
  margin-right: 0.5714285rem;
}
.ExpirationDateMM1 {
  display: inline-block;
  width: 30%;
}
.ExpirationDateMM2 {
  display: inline-block;
  width: 50%;
  text-align: left;
}
.ExpirationDateYY1 {
  display: inline-block;
  width: 50%;
  text-align: right;
}
.CVCWrapper {
  display: inline-block;
  width: 45%;
  text-align: right;
}
.CVCTextboxWrapper {
  display: inline-block;
  width: 25%;
  text-align: right;
}
.EmailFieldWrapper {
  margin-top: 0.9375rem;
  margin-right: 0.571428rem;
}
.ButtonWrapper {
  margin-right: 0.6rem;
  margin-top: 0.9375rem;
  width: 34.85rem;
  margin-left: 2rem;
}
button.btn,
button.btn:hover {
  background-color: #629ac4;
  border-radius: 0%;
  font-size: 1.rem;
  width: 7rem;
  height: 2.5rem;
  margin: 0.6rem !important;
  justify-content: center;
  vertical-align: middle;
}
#ExpDateMM,
#ExpDateYY {
  width: 90%;
}
.DetailRightEnd
{
  margin-right: 1.5rem !important;
}
.btn-secondary
{
  border-width: 0rem !important;
}
#PaymentButttonsDiv,#LandPaymentButttonsDiv
{
  margin-top:0.8rem;
  margin-left:0rem !important;
  float:right;
  /*margin-right:-0.5rem;*/
}
#PaymentButttonsDivLandRegPay
{
  margin-top:0.8rem;
  margin-left:0rem !important;
  float:right;
  /*margin-right:-0.25rem;*/
  margin-bottom:5rem !important;
}
#PaymentButttonsLocateDNDiv
{
  margin-top:0.8rem;
  margin-left:0rem !important;
  float:right;
  margin-right:0rem;
}
#PaymentSuccessBold{
  font-weight: 700;
}
@media only screen and (min-width: 1000px){
  #LandPaymentDataForm
  {
    display: flex;
    justify-content: left;
    align-content: center;
    align-items: center;
    margin-top: 0.5rem;
    /*margin-left: 1.942rem;*/
    width: 100%;
  }
  .ButtonWrapper2 {
    margin-right: 0.0rem;
    margin-top: 0.8rem;
    width: 100%;
    /*margin-left: 1.8rem;*/
    text-align: right;
  }
  .ButtonDNWrapper2 {
    margin-right: 0.0rem;
    margin-top: 0.4rem;
    width: 100%;
    /*margin-left: 1.8rem;*/
    text-align: right;
  }
  .PaymentTitle {
    font-family: Verdana, Geneva, sans-serif;
    font-size: 0.85rem !important;
    text-align: left;
    font-weight: normal !important;
    font-stretch: Normal;
    line-height: 1.5rem !important;
    width: 33.25rem;
    margin-top: 1.1rem;
  }
}

@media only screen and (min-width: 50px) and (max-width: 900px) {
  .ButtonWrapper2 {
    display: flex;
    flex-direction: row-reverse;
    margin-right: 0.5rem;
    margin-top: 0.5rem;
    width: 19rem;
    /*margin-left: 1.8rem;*/
    text-align: right;
  }
  #PaymentButttonsDiv,#LandPaymentButttonsDiv
  {
    margin-top:0.5rem;
    margin-left:0rem !important;
    float:right;
    /*margin-right:-0.5rem;*/
  }
  #LandPaymentDataForm
  {
    display: flex;
    justify-content: left;
    align-content: center;
    align-items: center;
    margin-top: 0.5rem;
    /*margin-left: 1.942rem;*/
    width: 19rem;
  }
  .PaymentTitle {
    font-family: Verdana, Geneva, sans-serif;
    font-size: 0.85rem !important;
    text-align: left;
    font-weight: normal !important;
    font-stretch: Normal;
    line-height: 1.5rem !important;
    width: 19rem;
    margin-top: 1.1rem;
  }
}
