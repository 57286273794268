#AboutContentDiv {
  width: 62.3rem;
  height: 25rem;
  visibility: hidden;
}

#ContactUsSincerelyDiv {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
#AboutusImageDiv {
  display: inline;
  float: right;
  width: 10rem !important;
}
/*#AboutUsMinisterImage
{
  /*border-bottom-left-radius: 25%;
  border-bottom-right-radius: 25%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
#ReceptionHoursDiv
{
  border-top:2px #dfe5ee solid;
  padding-top:0.5rem;
  margin-top:0.5rem;
  width:22rem;
}*/
.ReceptionHoursTitle {
  font-size: 1.5rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
  color: #629ac4;
  margin-top: 1.7rem;
  display: block;
  margin-bottom: 1rem;
}
#ContactUsDiv {
  width: 31.1rem;
  display: inline;
  float: left;
}
#WriteUsDiv {
  width: 31.1rem;
  display: inline;
  float: right;
}
.ContactusDetails {
  color: #007bff;
}

@keyframes pageAnimationContactUs {
  from {
    width: 1px;
    height: 1px;
    top: 500px;
    left: 288px;
  }
  to {
    width: 63.2rem;
    height: 31rem;
    top: 103px;
    left: 323px;
  }
}

@keyframes pageAnimationAboutUs {
  from {
    width: 1px;
    height: 1px;
    top: 450px;
    left: 288px;
  }
  to {
    width: 63.2rem;
    height: 31rem;
    top: 103px;
    left: 323px;
  }
}

@media only screen and (min-width: 1000px) {
  #ContactusWrapper {
  display: flex;
  justify-items: center;
  margin-left: 1.75rem;
  /*margin-top: 1.479rem;
  margin-bottom: 1.479rem;*/
  margin-right: 1.75rem;
  /*height: 31.2rem;*/
  max-width: 100% !important;
  background-color: #f3f7fa;
  height: 100%;
  }
  .ContactusTitle {
    /* width: 9.938rem;
    height: 1.813rem; */
    font-size: 1.5rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: normal;
    text-align: left;
    color: #629ac4;
    margin-top: 2.757rem;
    margin-left: 3.942rem;
    display: block;
  }
  #AboutusTextDiv {
    display: inline;
    float: left;
    width: 50rem !important;
    padding-left: 4rem;
    padding-top: 1rem;
    line-height: 2.19rem;
    font-size: 0.85rem;
    font-weight: 400;
    color: #252930;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
  }
  #ContactusContentDiv {
    padding-left: 4rem;
    padding-top: 1rem;
    line-height: 2.19rem;
    font-size: 0.85rem;
    font-weight: 400;
    color: #252930;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    visibility: visible;
  }
  #WriteusContent {
    padding-left: 4rem;
    padding-top: 1rem;
    line-height: 2.19rem;
    font-size: 0.85rem;
    font-weight: 400;
    color: #252930;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    display:none;
  }
}

@media only screen and (min-width: 1400px) {
  #ContactusPage {
    max-height: 100% !important;
    padding-bottom: 5rem;
    /*height: 31rem !important;*/
    height: 100% !important;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    border-top: 0.177rem #1046ce solid;
    width: 99.6%;
    animation-name: pageAnimationContactUs;
    animation-duration: 0.75s;
    font-family: Verdana, Geneva, Tahoma, sans-serif !important;
  }

  #ContactusContentDiv {
    display: none;
    /*width:76%;*/
  }
}

@media only screen and (width: 1366px) {
  #ContactusPage {
    width: 63.2rem !important;
    max-height: 100% !important;
    padding-bottom: 5rem;
    height: 31rem !important;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    border-top: 0.177rem #1046ce solid;
    position: absolute;
    animation-name: pageAnimationContactUs;
    animation-duration: 0.75s;
    font-family: Verdana, Geneva, Tahoma, sans-serif !important;
  }

  #ContactusContentDiv {
    display: none;
    width: 63.2rem;
    height: 31rem;
  }

  #WriteusContent{
    display: none;
    height: 31rem;
  }
}

@media only screen and (min-device-width: 50px) and (max-device-width: 900px) {
  #ContactusWrapper {
    display: inline-block;
    margin-top: 0rem;
    justify-items: center;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    max-width: 100% !important;

    background-color: white;
    width: 20rem;
    height: 100%;
    position: relative;
    z-index: 2;
  }

  #ContactusPage {
    max-height: 100% !important;

    padding-bottom: 0rem;
    /*height: 31rem !important;*/
    height: 100%;
    box-shadow: none;
    background-color: #ffffff;
    border-top: none;
    border: none;
    width: 100%;
    /*animation-name: pageAnimationContactUs;
    animation-duration: 0.75s;*/
    font-family: Verdana, Geneva, Tahoma, sans-serif !important;
  }

  #ContactusContentDiv {
    display: none;
    /*width:76%;*/
  }

  #ContactUsDiv {
    width: 100% !important;
    display: inline-block;
    float: left;
  }
  #WriteUsDiv {
    /* width: 22rem; */
    width: 100%;
    display: inline-block;
    float: left;
    margin-top: 1rem;
  }
  .ContactusDetails {
    color: #007bff;
  }
  #WriteusContent {
    padding-top: 1rem;
    line-height: 2.19rem;
    font-size: 0.85rem;
    font-weight: 400;
    color: #252930;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    display: none;
  }
  #ContactusContentDiv {
    padding-top: 1rem;
    line-height: 2.19rem;
    font-size: 0.85rem;
    font-weight: 400;
    color: #252930;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    visibility: visible;
  }
  .ContactusTitle {
    /* width: 9.938rem;
  height: 1.813rem; */
    font-size: 1.5rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.19;
    letter-spacing: normal;
    text-align: left;
    color: #629ac4;
    margin-top: 0.5%;
    display: block;
  }

  .ReceptionHoursTitle {
    font-size: 1.5rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: normal;
    text-align: left;
    color: #629ac4;
    margin-top: 1rem;
    display: block;
    margin-bottom: 1rem;
  }

  /*************/
  #AboutContentDiv {
    width: 100%;
    height: 100%;

    visibility: hidden;
  }
  #AboutusTextDiv {
    display: inline-block;
    width: 100% !important;
    padding-top: 1rem;
    line-height: 2.19rem;
    font-size: 0.85rem;
    font-weight: 400;
    color: #252930;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    word-wrap: break-word;
  }
  #ContactUsSincerelyDiv {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  #AboutusImageDiv {
    display: block;
    float:left;
    margin-top: 0.5rem;
    width: 10rem !important;
  }
}
