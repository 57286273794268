@viewport {
  width: device-width;
  height: device-width;
  zoom: 1;
  min-width: 22.5rem !important;
  min-width: 100%;
}

img,
video,
canvas {
  max-width: 100%;
}

code {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

/* .navbar {
  margin-bottom: 30px;
} */

.clickable {
  cursor: pointer;
}

root {
  font-family: Verdana, Geneva, Tahoma, sans-serif !important;
  font-weight: normal;
  font-size: 1rem;
  background-color: #f3f7fa;
  width: 100%;
  height: 100%;
}
main {
  background-color: #f3f7fa;
  width: 100% !important;
  height: 100% !important;
  max-width: 100% !important;
  max-height: 100% !important;
  padding: 0em;
  margin: 0em;
  overflow-y:scroll;
}

footer {
  background-color: #f3f7fa;
}

label {
  font-family: Verdana, Geneva, Tahoma, sans-serif !important;
  font-size: 0.85rem;
  color: #252930;

  text-align: left;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.19;
  letter-spacing: normal;
  word-wrap: unset;
  flex-wrap: nowrap;
  overflow-wrap: unset;
  word-break: unset;
}

.labelDiv {
  overflow-wrap: unset;
  word-wrap: none;
}

.readonlyInput {
  font-family: Verdana, Geneva, Tahoma, sans-serif !important;
  font-size: 0.85rem !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  width: 22rem !important;
  height: 1.8rem !important;
  background-color: lightgray !important;
  border: none;
  text-align: left;
  color: #42464c;
  outline: none !important;
  width: fit-content;
}

input:-moz-read-only {
  /* For Firefox */
  background-color: lightgray !important;
}

input:read-only {
  background-color: lightgray !important;
}
#mobileInput {
}

#mobileInputReadonly {
  background-color: lightgray !important;
}

.mobileInput {
  font-family: Verdana, Geneva, Tahoma, sans-serif !important;
  font-size: 0.85rem !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  width: 22rem !important;
  height: 1.8rem !important;
  background-color: #dfe5ee !important;
  border: none;
  text-align: left;
  /* padding-left: 0.5rem;
  padding-bottom: 0rem; */
  /* color: #42464c; */
  color: black;
  /* border: thin 0.1 #dfe5ee;
  border-collapse: collapse; */
  outline: none !important;
  width: fit-content;
}

input {
  font-family: Verdana, Geneva, Tahoma, sans-serif !important;
  font-size: 0.85rem !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;

  height: 1.8rem !important;
  background-color: #dfe5ee !important;
  border: none;
  text-align: left;
  /* padding-left: 0.5rem;
  padding-bottom: 0rem; */
  /* color: #42464c; */
  color: black;
  /* border: thin 0.1 #dfe5ee;
  border-collapse: collapse; */
  outline: none !important;
  width: fit-content;
}
select {
  border: none !important;
  width: 100% !important;
  background-color: #dfe5ee !important;
  height: 1.8rem !important;
  font-size: 0.85rem !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 2;
  letter-spacing: normal !important;
  text-align: left;
  color: #42464c;
  /* padding-left: 0.5rem; */
  text-transform: none;
  -webkit-user-select: none;
}

span {
  /* font-size: 0.85rem; */
  /* 
  font-family: Verdana, Geneva, Tahoma, sans-serif !important;
  font-size: 0.85rem !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important; */
}
input:focus,
/*input::selection,*/
input:active,
/*input::before
input::after,*/
select:focus,
option:focus {
  border: none !important;
  border-style: none !important;
  outline: solid 0.01rem lightblue !important;
}

/* *, */
input,
select,
textarea,
option,
button,
label {
  outline: none !important;
  padding-left: 0.5rem !important;
  margin: 0 !important;
}

form {
  width: 100%;
  height: auto;
  /*border: 0.001rem solid lightblue;*/
  margin-left: 0em;
  margin-top: 0rem;
  /*padding-left: 0.5rem;
  padding-right: 0.5rem;*/
  /* padding: 0.5rem; */
}

a,
a:hover,
a:active,
span:any-link a span:active,
a span:hover {
  cursor: pointer;
  text-decoration: none !important;
  border-style: none;
}

/* Bootstrap override -->*/
.container {
  margin-left: 0;
}

.row {
  padding: 0em !important;
  margin-top: 0.5em !important;
  margin-bottom: 0em !important;
  margin-left: 0em !important;
  margin-right: 0em !important;
  font-size:0.85rem !important;
}
.rowStage {
  padding: 0em !important;
  /*margin-top: 0.0em !important;
  margin-bottom: 0em !important;
  margin-left: 0em !important;
  margin-right: 0em !important;*/
}

.form-row {
  padding: 0em !important;
  margin-top: 0em !important;
  margin-bottom: 0em !important;
  margin-left: -0.2em !important;
  margin-right: 0em !important;
}

.form-row-search {
  padding: 0em !important;
  margin-top: 0em !important;
  margin-bottom: 0.01em !important;
  margin-left: 0.1em !important;
  margin-right: 0em !important;
}
/*.col {
  padding: 0em;
}*/
.colTextbox {
  width: 100% !important;
}

.colOTLoginInput {
  flex-basis: 55%;
  flex-grow: 1;
  min-width: 0;
  max-width: 100%;
  position: relative;
  padding-right: 15px;
  padding-left: 15px;
}

.colOTLoginPhoneInput {
  flex-basis: 55%;
  flex-grow: 1;
  min-width: 0;
  max-width: 100%;
  position: relative;
  /*padding-left: 15px;*/
}


.colOTLoginSelect {
  flex-basis: 45%;
  flex-grow: 1;
  min-width: 0;
  max-width: 100%;
  position: relative;
  padding-right: 15px;
  padding-left: 15px;
}

.d-flex {
  margin-left: 2rem !important;
}

.ErrorInputFieldPayment {
  border: 1px solid red !important;
  width: 100% !important;
}

.ErrorInputField {
  border: 1px solid red !important;
  width: 100% !important;
}
.ErrorInputFieldStyle {
  border: 1px solid red !important;
}

.InputWrapperDiv {
  display: inline;
  float: left;
  width: 100%;
}
.WarningSignDiv {
  display: inline;
  float: right;
}

.tooltip {
  position: relative !important;
  display: inline-block;
  opacity: 1 !important;
}

.tooltip .tooltiptext {
  display: none;
  width: auto;
  background-color: #f5f5f5;
  color: #000000;
  text-align: center;
  border: 1px solid #629ac4;
  border-radius: 6px;
  padding: 5px;
  white-space: nowrap;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  margin-left: -2.9rem;
  margin-top: 0.6rem;
}

.tooltip:hover .tooltiptext {
  display: block;
}

/*.tooltip .tooltiptextError {
  display: none;
  width: auto;
  background-color: #f5f5f5;
  color: #000000;
  text-align: center;
  border: 1px solid #629ac4;
  border-radius: 6px;
  padding: 5px;
  white-space: nowrap;
}*/
/*.tooltip:hover .tooltiptextError {
  display: block !important;
}*/

.ErrorMessageDiv,
#ErrorMessageDiv {
  color: red;
  font-style: italic;
  font-weight: normal;
  font-size: 0.7rem;
  visibility: hidden;
  /*margin-left:1.3rem;*/
  height: 1rem;
  margin-top: 0.3rem;
  margin-bottom: 0.3rem;
}
.ErrorMessageDivMarginleft {
  color: red;
  font-style: italic;
  font-weight: normal;
  font-size: 0.7rem;
  visibility: hidden;
  /*margin-left: 1.6rem;*/ /*Remarked by Shlomy Akons 19/11/2020*/
  height: 1rem;
  margin-top: 0.3rem;
}
.ErrorMessageDivMarginleftJoinUs {
  color: red;
  font-style: italic;
  font-weight: normal;
  font-size: 0.7rem;
  visibility: hidden;
  margin-left: 1rem;
  height: 1rem;
  margin-top: 0.3rem;
}

.ErrorMessageDivPersonalDetails {
  color: red;
  font-style: italic;
  font-weight: normal;
  font-size: 0.7rem;
  visibility: hidden;
  margin-left: 0rem;
  height: 1rem;
  margin-top: 0.3rem;
  display: inline-flex;
  justify-content: flex-start;
  width: 22rem !important;
}
.ErrorMessageDivTokenError {
  color: red;
  font-style: italic;
  font-weight: normal;
  font-size: 0.7rem;
  visibility: hidden;
  margin-left: 3rem;
  height: 1rem;
  margin-top: 0.3rem;
}

.ErrorMessageExclamationMark {
  font-weight: bold;
  font-size: 1rem;
}
.SuccessMessage {
  color: green;
  font-style: italic;
  font-weight: normal;
  font-size: 0.7rem;
  visibility: visible;
  /* margin-left: 3rem; */
  height: 1rem;
  margin-top: 0rem;
  width: 22rem !important;
  display: inline-flex;
}

/*********************************************** NEW ***********************************************************************/

/* default styles here for older browsers. 
       I tend to go for a 600px - 960px width max but using percentages
    */

@media only screen and (min-width: 1000px) {
  input {
    width: 22rem !important;
  }
  .ErrorMessageDivLogIn {
    color: red;
    font-style: italic;
    font-weight: normal;
    font-size: 0.7rem;
    visibility: hidden;
    margin-left: 1rem;
    height: 2rem !important;
    margin-top: 0.5rem;
    display: inline-flex;
    justify-content: flex-start;
    width: 22rem !important;
  }
  body {
    width: 100% !important;
    height: 100% !important;
    max-width: 100% !important;
    max-height: 100% !important;
    min-width: 22.5rem !important;
  
    margin: 0;
    padding: 0 0 0 0;
    background: #f3f7fa;
    /* overflow: scroll; */
    font-family: Verdana, Geneva, Tahoma, sans-serif !important;
    /* font-family: Verdana, Geneva, Tahoma, sans-serif, -apple-system,
      BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
      "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif; */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #f3f7fa;
    background-repeat: inherit;
  
    overflow-x: hidden; /* Hide horizontal scrollbar */
  }
}

/* MOBILES */
@media only screen and (min-device-width: 50px) and (max-device-width: 900px) {
  root {
    background-color: white;
    width: 100%;
    height: 100%;
  }
  main {
    background-color: white;
    width: 100% !important;
    height: 100% !important;
    max-width: 100% !important;
    max-height: 100% !important;
    padding: 0em;
    margin: 0em;
    overflow-y:scroll;
  }

  footer {
    background-color: white;
  }

  form {
    width: 19rem;
    height: auto;
    /*border: 0.001rem solid lightblue;*/
    margin-left: 0rem;
    margin-top: 0rem;
    /*padding-left: 0.5rem;
    padding-right: 0.5rem;*/
    /* padding: 0.5rem; */
  }

  input {
    width: 20rem !important;
  }

  .readonlyInput {
    font-family: Verdana, Geneva, Tahoma, sans-serif !important;
    font-size: 0.85rem !important;
    font-weight: normal !important;
    font-stretch: normal !important;
    font-style: normal !important;
    width: 20rem !important;
    height: 1.8rem !important;
    background-color: lightgray !important;
    border: none;
    text-align: left;
    color: #42464c;
    outline: none !important;
    width: fit-content;
  }

  .mobileInput {
    font-family: Verdana, Geneva, Tahoma, sans-serif !important;
    font-size: 0.85rem !important;
    font-weight: normal !important;
    font-stretch: normal !important;
    font-style: normal !important;
    width: 20rem !important;
    height: 1.8rem !important;
    background-color: #dfe5ee !important;
    border: none;
    text-align: left;
    /* padding-left: 0.5rem;
    padding-bottom: 0rem; */
    /* color: #42464c; */
    color: black;
    /* border: thin 0.1 #dfe5ee;
    border-collapse: collapse; */
    outline: none !important;
    width: fit-content;
  }
  .ErrorMessageDivLogIn {
    color: red;
    font-style: italic;
    font-weight: normal;
    font-size: 0.7rem;
    visibility: hidden;
    margin-left: 1rem;
    height: 3.5rem !important;
    margin-top: 0.5rem;
    display: inline-flex;
    justify-content: flex-start;
    width: 17rem !important;
  }
  body {
    width: 100% !important;
    height: 100% !important;
    max-width: 100% !important;
    max-height: 100% !important;
    min-width: 22.5rem !important;
  
    margin:0;
    padding: 0 0 0 0;
    margin-left: -1rem !important;
    /*background: #f3f7fa;*/
    /* overflow: scroll; */
    font-family: Verdana, Geneva, Tahoma, sans-serif !important;
    /* font-family: Verdana, Geneva, Tahoma, sans-serif, -apple-system,
      BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
      "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif; */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /*background-color: #f3f7fa;*/
    background-repeat: inherit;
  
    overflow-x: hidden; /* Hide horizontal scrollbar */
  }
  /* styles for mobile browsers smaller than 480px; (iPhone) */
}
@media only screen and (min-device-width: 350px) and (max-device-width: 800px) {
  body {
    width: 100% !important;
    height: 100% !important;
    max-width: 100% !important;
    max-height: 100% !important;
    min-width: 22.5rem !important;
    /*min-height: 43rem !important;*/
    margin:0;
    padding: 0 0 0 0;
    margin-left: -1rem !important;
    /*background: #f3f7fa;*/
    /* overflow: scroll; */
    font-family: Verdana, Geneva, Tahoma, sans-serif !important;
    /* font-family: Verdana, Geneva, Tahoma, sans-serif, -apple-system,
      BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
      "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif; */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /*background-color: #f3f7fa;*/
    background-repeat: inherit;
    overflow-y:hidden;
    overflow-x: hidden; /* Hide horizontal scrollbar */
  }
}

/* for all mobile/cellphone widths */
@media only screen and (min-width: 200px) and (max-width: 767px) and (orientation: portrait) {
  /* //Put your CSS here for 200px to 767px width devices (cover all mobile portrait width // */
}

/* for all mobile/cellphone widths */
@media only screen and (min-width: 200px) and (max-width: 767px) and (orientation: landscape) {
  /* //Put your CSS here for 200px to 767px width devices (cover all mobile portrait width // */
}

/* TABLETS*/
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  /* //Put your CSS here for 768px to 1024px width devices(covers all iPad portrait width //
    */
}

/* different techniques for iPad screening */
/* For portrait layouts only */

@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
}
/* For landscape layouts only */

@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: landscape) {
}

/*DESKTOPS*/
/* (1920x1080) Full HD Display */
@media screen and (max-width: 1920px) {
}
/* (1366x768) WXGA Display */
@media screen and (max-width: 1366px) {
}
/* styles for browsers larger than 960px; */
@media only screen and (min-width: 960px) {
}
/* styles for browsers larger than 1440px; */

@media only screen and (min-width: 1440px) {
}
@media only screen and (min-width: 2000px) {
  /* for sumo sized (mac) screens */
}

/*********************************************** NEW ***********************************************************************/
