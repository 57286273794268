/* #LandRegistryWrapper {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  border-top: 0.177rem #e14e17 solid;
  margin-left: 1.75rem;
  margin-right: 1.75rem;
  margin-top: 1.479rem;
  margin-bottom: 1.479rem;
  width: 95%;
  max-width: 98%;
  max-height: 100% !important;
  height: 100% !important;
} */


/* CSS for the spinner modal */
.spinner-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 200;
}

/* CSS for the spinner overlay */
.spinner-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* Grey background with transparency */
}

/* CSS for the spinner container */
.spinner-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}



/* CSS animation for spinner rotation */
@keyframes spin {
  0% { transform: rotate(0deg); } /* Starting position */
  100% { transform: rotate(360deg); } /* Ending position */
}








#LandRegistryWrapper {
  /*display: flex;*/
  /*justify-items: center;*/
  margin-left: 1rem;
  /*margin-top: 1.479rem;
  margin-bottom: 1.479rem;*/
  margin-right: 1rem;
  /*height: 31.2rem !important;*/
  height:91.5% !important;
  max-width: 100% !important;
  /*background-color: #f3f7fa;*/
}
#LandRegistryPage {
  /*width: 99% !important;*/
  max-height: 100% !important;
  padding-bottom: 5rem;
  height: 40rem !important;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  border-top: 0.177rem #e25824 solid !important;
  position: absolute;
  width:63.2rem;
}

.custom-placeholder {
  color: #252930;
}
#workArea {
  height:43rem !important;
}


@media only screen and (min-width:1400px) {
  #LandRegistryPageAnimation {
    /*width: 99% !important;*/
    padding-bottom: 5rem;
    /*height: 31rem !important;*/
    height:40rem !important;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    border-top: 0.177rem #e25824 solid !important;
    width:99.6%;
    animation-name: pageAnimationLandRegistry;
    animation-duration: 0.75s;
  }

  #PaymentsPageAnimation {
    /*width: 99% !important;*/
    max-height: 100% !important;
    padding-bottom: 5rem;
    /*height: 31rem !important;*/
    height:100% !important;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    border-top: 0.177rem #e25824 solid !important;
    width:99.6%;
    animation-name: pageAnimationPayments;
    animation-duration: 0.75s;
   }
}

@media only screen and (width:1366px) {
  #LandRegistryPageAnimation {
    width: 63.2rem !important;
    position: absolute;
    max-height: 100% !important;
    padding-bottom: 5rem;
    height: 38rem !important;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    border-top: 0.177rem #e25824 solid !important;
    animation-name: pageAnimationLandRegistry;
    animation-duration: 0.75s;
  }

  #PaymentsPageAnimation {
    width: 63.2rem !important;
     max-height: 100% !important;
     padding-bottom: 5rem;
     height: 31rem !important;
     box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
     background-color: #ffffff;
     border-top: 0.177rem #e1a417 solid;
     position: absolute;
     animation-name: pageAnimationPayments;
     animation-duration: 0.75s;
   }
}



#PaymentsPage {
  /*width: 99% !important;*/
   max-height: 100% !important;
   padding-bottom: 5rem;
   height: 31rem !important;
   box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
   background-color: #ffffff;
   border-top: 0.177rem #e1a417 solid;
   position: absolute;
   width:63.2rem;
 }

/* .LandRegistryWrapper {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  /* text-align: center; */
/* background-color: #ffffff;
  border-top: 0.177rem #e14e17 solid; */
/* min-height: 32rem !important; */
/* float: rigth;
  margin-left: 1.75rem;
  margin-top: 1.479rem;
  margin-bottom: 1.479rem;
  margin-right: 1.75rem; */
/* width: 99%; */
/* width: 93.1%; */
/* height: 100%; */
/* max-width: 99%; */
/* width: 95%; */
/* max-height: 100% !important; */
/* } */

.LandRegistryTitle {
  /* width: 9.938rem;
  height: 1.813rem; */
  font-size: 1.5rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
  color: #629ac4;
  /*margin-top: 2.757rem;*/
  /*margin-left: 3.942rem;*/
  display: block;
  width:20rem;
  height:2rem;
  margin-bottom:0.5rem;
}

#LandRegistryMain {
  display: inline-flexbox;
  width: 100% !important;
  height: 100% !important;
}
.SearchFormDesc {
  display: block;
  font-size: 0.85rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.19;
  letter-spacing: normal;
  text-align: left;

  margin-top: 1rem;
  /*margin-left: 2rem;*/
  text-align: justify;
  color: #252930;
}

.dropdownSearchDiv {
  display: inline-flex;
  font-size: 0.85rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.19;
  letter-spacing: normal;
  text-align: left;
  padding: 0%;
  /*margin-top: 4%;*/

  margin-left: 0rem;
  margin-right: 0rem;
  /*margin-bottom: 3%;*/
  text-align: left;
  color: #252930;
  width: 22rem !important;
}


/*
 #workArea {
    float: right;
    width: 78.7% !important;
    height: 100% !important;
    margin-top: 0rem !important;
    background-color: lawngreen;
    justify-content: center;
    z-index: 1;
    background-color: #f3f7fa;
  }

 
  .workAreaPage {
    width: 83.1%;
    height: 90.4%;

    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
  }

*/

/* 
#LandRegistryMain {
  width: 75%;
} */


@media only screen and (min-width: 1000px){
  .inputSearchDiv {
    display: inline-flex;
    font-size: 0.85rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.8rem;
    letter-spacing: normal;
    text-align: left;
    padding: 0%;
    /*margin-top: 4%;*/
  
    margin-left: 0rem;
    margin-right: 0rem;
    /*margin-bottom: 3%;*/
    text-align: left;
    color: #252930;
    width:32.6rem;
  }
  
  .dropdownSearchDiv {
    display: inline-flex;
    font-size: 0.85rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.8rem;
    letter-spacing: normal;
    text-align: left;
    padding: 0%;
    /*margin-top: 4%;*/
  
    margin-left: 0rem;
    margin-right: 0rem;
    /*margin-bottom: 3%;*/
    text-align: left;
    color: #252930;
    width: 22rem !important;
  }

  .searchInputTxt
  {
    width:22rem !important;
  }
  .ColumnTitle {
    width: 20% !important;
    margin-left: 1.479rem;
    margin-top: 0rem;
    display: inline;
    color: #252930;
  }
  .confirmButtonsDiv {
    display: inline-flex;
    width: 100%;
    margin-top: 0.8rem;
    /*margin-left: 1.8rem;*/
    position: relative;
    justify-content: flex-end;
    place-content: flex-end;
    align-content: flex-end;
  }

  .confirmButtonDiv {
    /* width: 5.784rem;
    height: 2.131rem; */
    /* background-color: #629ac4; */
    justify-content: center;
    justify-items: center;
    align-content: center;
    vertical-align: middle;
    margin-top: -10rem;
    margin-right: 0rem;
    float: right;
    display: inline-flex;
  }
  #SearchLandResultsTitle {
    /* width: 32.132rem;
    height: 4.392rem; */
    /*margin-left: 2rem;*/
    margin-top: 1rem;
    font-size: 0.85rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.88;
    letter-spacing: normal;
    text-align: justify;
    color: #252930;
    width: 100%;
  }

  .SearchResultItem {
    width: 7.567rem;
    height: 2.218rem;
    width: 20%;
    background-color: #f5f5f5;
    display: inline-flex;
    margin-left: 4.5%;
    margin-bottom: 1rem;
    padding: 0.2rem;
    margin-top: 0.4rem;
    align-items: center;
    font-size: 0.85em;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 4.5;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
    justify-content: center;
    justify-items: center;
    align-content: center;
    vertical-align: middle;
    display: inline-flex;
  }

  #SearchResultData {
    width: 100%;
    /* height: 40%; */
    margin-top: 0rem;
    margin-left: 0rem;
    display: inline-flex;
    margin-bottom: 5rem;
    margin-top: 0rem;
    margin-left: 0rem;
    padding-top: 0rem;
    padding-bottom: 10rem;
  
    font-size: 0.85rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 3.5;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
    padding-right:0.6rem;
  }

  .SearchLandResults {
    /* width: 34.748rem !important;
    height: 7rem !important; */
  
    width: 100%;
    height: 7rem;
    border: solid 3px #dfe5ee;
    /*margin-left: 1.827rem;
    margin-right: 1.827rem;*/
    margin-top: 0.7rem;
    justify-content: center;
    justify-items: center;
    align-content: center;
    display: block;
  }

  .LandRegistryTitle {
    /* width: 9.938rem;
    height: 1.813rem; */
    font-size: 1.5rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: normal;
    text-align: left;
    color: #629ac4;
    margin-top: 2.757rem;
    margin-left: 3.942rem;
    display: block;
    width:20rem;
    height:2rem;
    margin-bottom:0.5rem;
  }


  #LandRegistryWrapper {
    display: flex;
    justify-items: center;
    margin-left: 1.75rem;
    /*margin-top: 1.479rem;
    margin-bottom: 1.479rem;*/
    margin-right: 1.75rem;
    /*height: 31.2rem !important;*/
    height:91.5% !important;
    max-width: 100% !important;
    background-color: #f3f7fa;
  }

  #LandRegistryData {
    display: inline-block !important;
    width: 55%;
    /* justify-content: flex-start; */
    float: left;
    padding-left: 2rem;
    margin-left:2rem;
    /* padding-left: 2.5rem; */
  }

  #LandRegistryStages {
    display: inline-block;
    justify-content: center;
    /* justify-content: space-between; */
    width: 40% !important;
    /* float: center !important;
    margin-right: 10rem; */
    /* margin-left: 60%; */
    /*margin-left: 5rem;*/
    padding-left: 20%;
    margin-top: 0rem;
    /* container d-block justify-content-center */
    position: relative;
  }

  .LandRegistryStages{
    display: flex;
    justify-content: center;
    width:40%;
  }

  #SearchFormDiv {
    display: flex;
    justify-items: flex-start;
    place-items: flex-start;
    place-content: flex-start;
    margin-top: 0rem;
    /*padding-left: 2.5rem;*/
    /*width: 76%;*/
  }

  /*#PaymentsPageAnimation {
    width: 63.2rem;
     max-height: 100% !important;
     padding-bottom: 5rem;
     height: 31rem !important;
     box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
     background-color: #ffffff;
     border-top: 0.177rem #e1a417 solid;
     position: absolute;
     animation-name: pageAnimationPayments;
     animation-duration: 0.75s;
   }*/
}
#LandRegistryStagesDescription {
  display: inline-block;
  justify-content: center;
  width: 50% !important;
  padding-right: 4rem !important;
  position: relative;
  /* margin-top: 0%;
  margin-left: 0%; */
}

.stageDescDiv {
  width: 8.437rem;
  height: 3.805rem;
  font-size: 1rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: left;
  color: #629ac4;
}

#SearchResultTitles {
  /* margin-top: 15rem; */
  margin-left: 0rem;
  width: 100%;
  height: 30%;
  display: inline-flex;
  padding-top: 0rem;
  font-size: 0.85rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 3;
  letter-spacing: normal;
  text-align: center;
  color: #252930;
  padding-right:0.6rem;
  /* margin-bottom: 0.1rem; */
}

.ResultRow
{
  height:1.5rem;
  margin-top:0.5rem;
  margin-bottom: 0.5rem;
  display: flex;
  flex-direction: row;
}
.confirmButtonDiv {
  /* width: 5.784rem;
  height: 2.131rem; */
  /* background-color: #629ac4; */
  justify-content: center;
  justify-items: center;
  align-content: center;
  vertical-align: middle;
  margin-top: -10rem;
  margin-right: 0rem;
  float: right;
  display: inline-flex;
}
#confirmButton {
  width: 5.784rem;
  height: 2.131rem;
  font-family: Titillium + Web;
  font-size: 1.125rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.94;
  letter-spacing: normal;
  text-align: center;
  background-color: #629ac4;
  color: #ffffff;
  margin-top: 2rem;
  margin-left: 87%;
  padding: 0.1rem;
  border: none;
}
#stageDesc1
{
  justify-content: left !important;
}

@keyframes pageAnimationLandRegistry{
  from {
    width: 1px;
    height: 1px;
    top: 270px;
    left: 288px;
  }
  to {
    width: 63.2rem;
    height: 31rem;
    top: 103px;
    left: 323px;
  }
}

.btnLndReg
{
  margin: 0rem 0rem 0.5rem 0.25rem !important;
  min-width: 5.775rem;
  height: 2.128rem;
  background-color: #629ac4;
  color: white;
  font-size: 1rem;
  border: none;
  align-items: center;
  vertical-align: middle;
  justify-content: center;
}
.btnReceipt
{
  margin:  0rem 0rem 0.5rem 0.5rem  !important;
  min-width: 5.775rem;
  height: 2.128rem;
  background-color: #629ac4;
  color: white;
  font-size: 1rem;
  border: none;
  align-items: center;
  vertical-align: middle;
  justify-content: center;
}

.PhoneAndPinCode
{
  display: flex;
  flex-wrap: wrap;
}
.PhoneNumberWrapper
{
  flex-basis: 67%;
  flex-grow: 1;
  min-width: 0px;
  max-width: 100%;
  position: relative;
  padding-right: 0.5rem;
}
.PinCodeNumberWrapper
{
  flex-basis: 33%;
  flex-grow: 1;
  min-width: 0px;
  max-width: 100%;
  position: relative;
}
.LandRegistryComponentTitle
{
  font-size: 1.5rem;
  font-weight: 400;
  font-style:normal;
  margin-bottom:1rem;
}

@media only screen and (min-width: 50px) and (max-width: 900px) {
  #workArea
  {
    width:100%;
    display:flex;
    flex-direction: row;
    align-items: center;

  }
  input#Land_Name
  {
    width:17rem !important;
  }
  .confirmButtonsDiv {
    display: flex;
    width: 19rem;
    min-width:19rem;
    margin-top: 0.5rem;
    flex-direction: row-reverse;
  }
  .ColumnTitle {
    width: 8rem !important;
    margin-left: 1.479rem;
    margin-top: 0rem;
    /*display: inline;*/
    color: #252930;
  }

  .SearchResultItem {
    /*width: 7.567rem;*/
    width:8rem;
    /*height: 1.218rem;*/
    /*width: 20%;*/
    /*background-color: #f5f5f5;*/
    /*display: inline-flex;*/
    margin-left: 4.5%;
    /*margin-bottom: 1rem;
    padding: 0.2rem;
    margin-top: 0.4rem;*/
    /*align-items: center;*/
    /*font-size: 0.85em;*/
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    /*line-height: 4.5;*/
    letter-spacing: normal;
    text-align: left;
    color: #000000;
    /*justify-content: center;
    justify-items: center;
    align-content: center;*/
    vertical-align: middle;
    /*display: inline-flex;*/
  }

  #SearchResultData {
    width: 100%;
    /* height: 40%; */
    margin-top: 0rem;
    margin-left: 0rem;
    display: inline-flex;
    margin-bottom: 5rem;
    margin-top: 0rem;
    margin-left: 0rem;
    padding-top: 0rem;
    padding-bottom: 10rem;
  
    font-size: 0.85rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 3.5;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
    padding-right:0.6rem;
  }

  #LandRegistryWrapper {
    /*display: flex;*/
    /*justify-items: center;*/
    margin-left: 1rem;
    /*margin-top: 1.479rem;
    margin-bottom: 1.479rem;*/
    margin-right: 1rem;
    /*height: 31.2rem !important;*/
    height:91.5% !important;
    max-width: 100% !important;
    width:19rem;
    overflow: hidden;
    scrollbar-width: none;
    overflow-y:scroll !important;
    z-index: 1;
    /*background-color: #f3f7fa;*/
  }

  #LandRegistryWrapper::-webkit-scrollbar {
    display: none;
  }

  #LandRegistryData {
    display: inline-block !important;
    width: 100%;
    min-width:19rem;
    /*width: 55%;*/
    /* justify-content: flex-start; */
    /*float: left;*/
    /*padding-left: 2rem;*/
    /* padding-left: 2.5rem; */
  }
  .LandRegisterForm
  {
    padding-left:0rem !important;
    padding-right:0rem !important;
  }

  .LandRegistryTitle {
    /* width: 9.938rem;
    height: 1.813rem; */
    font-size: 1.5rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: normal;
    text-align: left;
    color: #629ac4;
    /*margin-top: 2.757rem;*/
    /*margin-left: 3.942rem;*/
    display: block;
    width:20rem;
    height:2rem;
    margin-bottom:0.5rem;
  }

  .SearchFormDesc {
    display: block;
    font-size: 0.85rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.88;
    letter-spacing: normal;
    text-align: left;
    width:19rem;
    margin-top: 1rem;
    /*margin-left: 2rem;*/
    text-align: justify;
    color: #252930;
  }

  .inputSearchDiv {
    display: inline-flex;
    font-size: 0.85rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.8rem;
    letter-spacing: normal;
    text-align: left;
    padding: 0%;
    /*margin-top: 4%;*/
  
    margin-left: 0rem;
    margin-right: 0rem;
    /*margin-bottom: 3%;*/
    text-align: left;
    color: #252930;
  }

  .dropdownSearchDiv {
    display: inline-flex;
    font-size: 0.85rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.8rem;
    letter-spacing: normal;
    text-align: left;
    padding: 0%;
    /*margin-top: 4%;*/
  
    margin-left: 0rem;
    margin-right: 0rem;
    /*margin-bottom: 3%;*/
    text-align: left;
    color: #252930;
    width: 19rem !important;
  }

  .LandRegisterForm{
    padding-left:0rem !important;
    padding-right:0rem !important;
  }
  #LandRegistryStages {
    display: flex;
    justify-content: normal;
    /* justify-content: space-between; */
    /*width: 40% !important;*/
    /* float: center !important;
    margin-right: 10rem; */
    /* margin-left: 60%; */
    /*margin-left: 5rem;*/
    /*padding-left: 20%;*/
    margin-top: 0rem;
    width:19rem;
    /* container d-block justify-content-center */
    /*position: relative;*/
  }
  .SearchLandForm
  {
    width:19rem;
    padding-left:0px !important;
    padding-right:0px !important;
  }

  #SearchFormDiv {
    display: flex;
    justify-items: flex-start;
    place-items: flex-start;
    place-content: flex-start;
    margin-top: 0rem;
    /*padding-left: 2.5rem;*/
    /*width: 76%;*/
  }

  #SearchLandResultsTitle {
    /* width: 32.132rem;
    height: 4.392rem; */
    /*margin-left: 2rem;*/
    margin-top: 1rem;
    font-size: 0.85rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.88;
    letter-spacing: normal;
    text-align: justify;
    color: #252930;
    width: 94%;
  }

  .SearchLandResults {
    /* width: 34.748rem !important;
    height: 7rem !important; */
  
    width: 19rem;
    min-width: 19rem;
    height: 9rem;
    border: solid 3px #dfe5ee;
    /*margin-left: 1.827rem;*/
    margin-right: 1.827rem;
    margin-top: 0.7rem;
    padding-bottom: 0.7rem;
    justify-content: center;
    justify-items: center;
    align-content: center;
    display: block;
  }

  .ColumnTitle {
    width: 8rem !important;
    margin-left: 1.479rem;
    margin-top: 0rem;
    /*display: inline;*/
    color: #252930;
  }

  .ResultRow
  {
    height:1.5rem;
    margin-top:0.5rem;
    margin-bottom: 0.5rem;
    display: flex;
    flex-direction: row;
  }
  .LandRegistryStages{
    display: flex;
    justify-content: center;
    width:19rem;
  }

  .btnLndReg
  {
    margin: 0.5rem 0rem 0.5rem 0.25rem !important;
    min-width: 5.775rem;
    height: 3rem;
    background-color: #629ac4;
    color: white;
    font-size: 1rem;
    border: none;
    align-items: center;
    vertical-align: middle;
    justify-content: center;
  }
  .btnReceipt
  {
    margin:  0.5rem 0.5rem 0.5rem 0rem  !important;
    min-width: 5.775rem;
    height: 3rem;
    background-color: #629ac4;
    color: white;
    font-size: 1rem;
    border: none;
    align-items: center;
    vertical-align: middle;
    justify-content: center;
  }
  .searchInputTxt
  {
    width:17rem !important;
  }
  .LandRegistryComponentTitle
  {
    font-size: 1.5rem;
    font-weight: 400;
    font-style:normal;
    margin-bottom:1rem;
  }
  .btnConfirmPay
  {
    width:6.25rem !important;
    margin: 0.5rem;
    min-width: 5.775rem;
    height: 2.5rem;
    background-color: #629ac4;
    color: white;
    font-size: 1rem;
    border: none;
    align-items: center;
    vertical-align: middle;
    justify-content: center;
  }
}
@media only screen and (min-device-width: 300px) and (max-device-width: 800px) {
  #LandRegistryWrapper {
    /*display: flex;*/
    /*justify-items: center;*/
    margin-left: 0.5rem;
    /*margin-top: 1.479rem;
    margin-bottom: 1.479rem;*/
    margin-top: 1rem;
    margin-right: 1rem;
    /*height: 31.2rem !important;*/
    height:91.5% !important;   /*91.5%*/
    max-width: 115% !important;
    /*background-color: #f3f7fa;*/
    width:115% !important;
    overflow: hidden;
    scrollbar-width: none;
    overflow-y:scroll !important;
    z-index: 1;
    /*padding-bottom:8rem !important;*/
  }

  #LandRegistryWrapper::-webkit-scrollbar {
    display: none;
  }

}
@media only screen and (min-width: 380px) and (max-width: 850px) {
  #LandRegistryWrapper {
    /*display: flex;*/
    /*justify-items: center;*/
    margin-left: 1.25rem;
    /*margin-top: 1.479rem;
    margin-bottom: 1.479rem;*/
    margin-right: 0.75rem;
    /*height: 31.2rem !important;*/
    height:91.5% !important;
    max-width: 105% !important;
    /*background-color: #f3f7fa;*/
    overflow: hidden;
    scrollbar-width: none;
    overflow-y:scroll !important;
    z-index: 1;
  }

  #LandRegistryWrapper::-webkit-scrollbar {
    display: none;
  }
}
@media only screen and (min-width: 400px) and (max-width: 950px) {
  #LandRegistryWrapper {
    /*display: flex;*/
    /*justify-items: center;*/
    margin-left: 2.5rem;
    /*margin-top: 1.479rem;
    margin-bottom: 1.479rem;*/
    margin-right: 0rem;
    /*height: 31.2rem !important;*/
    height:91.5% !important;
    max-width: 100% !important;
    /*background-color: #f3f7fa;*/
    overflow: hidden;
    scrollbar-width: none;
    overflow-y:scroll !important;
    z-index: 1;
  }

  #LandRegistryWrapper::-webkit-scrollbar {
    display: none;
  }
}

/* TABLETS*/
@media only screen and (min-width: 860px) and (max-width: 1024px) {
  #LandRegistryWrapper {
    margin-left: 1rem;
    margin-right: 0rem;
    height:91.5% !important;
    max-width: 100% !important;
  }

  .LandRegistryTitle {
    font-size: 1.5rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: normal;
    text-align: left;
    color: #629ac4;
    /* margin-top: 2.757rem; */
    /* margin-left: 3.942rem; */
    display: block;
    width: 20rem;
    height: 2rem;
    margin-bottom: 0.5rem;
    margin-left:3rem;
}

  #LandRegistryData {
    display: inline-block !important;
    width: 50%;
    min-width:22rem;
  }

  #SearchFormDiv {
    display: flex;
    justify-items: flex-start;
    place-items: flex-start;
    place-content: flex-start;
    margin-top: 0rem;
  }

  .SearchLandForm
  {
    width:40rem;
    padding-left:0px !important;
    padding-right:0px !important;
  }

  .inputSearchDiv 
  {
    display: inline-flex;
    font-size: 0.85rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.8rem;
    letter-spacing: normal;
    text-align: left;
    padding: 0%;
    /*margin-top: 4%;*/
    width:100% !important;
    margin-left: 0rem;
    margin-right: 0rem;
    /*margin-bottom: 3%;*/
    text-align: left;
    color: #252930;

  }

  .Land_No, .Block_No, .SurveyPlan_No, .CofO_No
  {
    width:22rem !important;
  }
/*
  #LandRegistryStages {
    display: flex;
    justify-content: normal;
    margin-top: 0rem;
    width:22rem;
  }*/

  .LandRegistryStages {
    display: flex;
    justify-content: center;
    width:19rem;
  }

    #LandRegistryStages {
    display: flex;
    justify-content: normal;
    margin-top: 0rem;
    width:20rem;
  }
  .row1 {
    width:1rem;
  }
  .stage_line {
    width: 1em;
    height: 1.5em;
    margin-top: 1rem;
    margin-bottom: 0em;
    /* margin-left: 2em; */
    padding: 0rem;
    color: #dfe5ee;
    border-top: 0.2rem solid #dfe5ee;
    z-index: 2;
}


/*


.LandRegistryStages {
    display: flex;
    justify-content: center;
    width:19rem;
  }

    #LandRegistryStages {
    display: flex;
    justify-content: normal;
    margin-top: 0rem;
    width:20rem;
  }



  .LandRegistryStages {
    display: flex;
    justify-content: center;
    width:40%;
  }

  #LandRegistryStages {
    display:inline-block;
    width:40% !important;
    padding-left:20%;
    margin-top:0rem;
    position:relative;
  }

  #LandRegistryStagesDescription {
    display:inline-block;
    justify-content:center;
    width:50% !important;
    padding-right:4rem !important;
    position:relative;
  }
*/
}