.container{
    display: flex;
    align-items: center;
  }
  
  .border{
    border-bottom: 1px solid black;
    width: 23%;
  }
  
  .content {
    padding: 0 10px 0 10px;

  }

  @media only screen and (min-width: 50px) and (max-width: 900px) {
    .border{
      border-bottom: 1px solid black;
      width: 50%;
    }
  }