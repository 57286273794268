.def_circle {
  width: 4em;
  height: 4em;
  background-color: #dfe5ee;
  background: #dfe5ee;
  color: #0e1317;
  font-weight: bolder;
  -moz-border-radius: 2em;
  -webkit-border-radius: 2em;
  border-radius: 2em;
  vertical-align: middle;
  justify-content: center;
  justify-items: baseline;
  text-align: center;
  align-content: center;
  z-index: 2;
}

.sel_circle {
  width: 4em;
  height: 4em;
  background-color: #629ac4;
  background: #629ac4;
  color: #ffffff;
  font-weight: bolder;
  -moz-border-radius: 2em;
  -webkit-border-radius: 2em;
  border-radius: 2em;
  vertical-align: middle;
  justify-content: center;
  justify-items: baseline;
  text-align: center;
  align-content: center;
  z-index: 2;
}

.stageNum {
  width: 2rem;
  height: 1.5rem;
  font-size: 1.5rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.89;
  letter-spacing: normal;
  z-index: 2;
}

.sel_desc {
  /* background-color: #629ac4; */
  color: #629ac4;
  width: 10rem !important;
  height: 3.805rem !important;
  font-size: 1rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: left;
  vertical-align: middle;
  justify-content: left !important;
  justify-items: baseline;
  text-align: left;
  align-content: center;
  align-content: center;
  margin-top: 0rem;
  z-index: 2;
}

.def_desc {
  /* background-color: #dfe5ee; */
  color: #0e1317;
  width: 9rem !important;
  height: 4rem !important;
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: left;
  vertical-align: middle;
  justify-content: left !important;
  justify-items: baseline;
  text-align: left;
  align-content: center;
  /*margin-top: 1.2rem !important;*/
  z-index: 2;
}

.stageDescDiv {
  width: 8.437rem;
  height: 3.805rem;
  position: fixed;
  font-size: 1rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: left;
  color: #629ac4;
  z-index: 2;
}
@media only screen and (min-width: 1000px){
  .stage_line {
    width: 1em;
    height: 1.5em;
    margin-top: 0rem;
    margin-bottom: 0em;
    margin-left: 2em;
    padding: 0rem;
    color: #dfe5ee;
    border-left: 0.2rem solid #dfe5ee;
    z-index: 2;
  }
  .sel_circle {
    width: 4em;
    height: 4em;
    background-color: #629ac4;
    background: #629ac4;
    color: #ffffff;
    font-weight: bolder;
    -moz-border-radius: 2em;
    -webkit-border-radius: 2em;
    border-radius: 2em;
    vertical-align: middle;
    justify-content: center;
    justify-items: baseline;
    text-align: center;
    align-content: center;
    z-index: 2;
  }
}
.last_stage_line {
  width: 1em;
  height: 2rem;
  margin-bottom: 0em;
  margin-left: 2em;
  color: #dfe5ee;
  border-left: 0.2rem solid #dfe5ee;
  z-index: 2;
}

.end_line {
  width: 35%;
  /* width: 17.4rem; */

  /* width: 100rem; */
  height: 0.174rem;
  line-height: 0.174rem;
  background-color: #d2d7da;
  float: right;
  margin-top: -0.5rem;
  z-index: 2;
  margin-bottom: 0rem;
}

.row1 {
  display: flex !important;
  place-items: center;
  margin: 0em !important;
}

@media only screen and (min-width: 50px) and (max-width: 900px) {
  .stage_line {
    width: 1em;
    height: 1.5em;
    margin-top: 1rem;
    margin-bottom: 0em;
    /*margin-left: 2em;*/
    padding: 0rem;
    color: #dfe5ee;
    border-top: 0.2rem solid #dfe5ee;
    z-index: 2;
  }

  .sel_circle {
    width: 4em;
    height: 4em;
    background-color: #629ac4;
    background: #629ac4;
    color: #ffffff;
    font-weight: bolder;
    -moz-border-radius: 2em;
    -webkit-border-radius: 2em;
    border-radius: 2em;
    vertical-align: middle;
    justify-content: center;
    justify-items: baseline;
    text-align: center;
    align-content: center;
    z-index: 2;
  }
}
