/* #LandRegistryWrapper {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  border-top: 0.177rem #e14e17 solid;
  margin-left: 1.75rem;
  margin-right: 1.75rem;
  margin-top: 1.479rem;
  margin-bottom: 1.479rem;
  width: 95%;
  max-width: 98%;
  max-height: 100% !important;
  height: 100% !important;
} */

#LinkReport {
background-color: #000000;
color: white;
line-height: 1.5;
}


.LandRegistryTitle {
  /* width: 9.938rem;
  height: 1.813rem; */
  font-size: 1.5rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
  color: #629ac4;
  /*margin-top: 2.757rem;*/
  /*margin-left: 3.942rem;*/
  display: block;
  width:20rem;
  height:2rem;
  margin-bottom:0.5rem;
}

#LandRegistryMain {
  display: inline-flexbox;
  width: 100% !important;
  height: 100% !important;
}

.SearchFormDesc {
  display: block;
  font-size: 0.85rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.19;
  letter-spacing: normal;
  text-align: left;

  margin-top: 1rem;
  /*margin-left: 2rem;*/
  text-align: left;
  color: #252930;
}

.inputSearchDiv {
  display: inline-flex;
  font-size: 0.85rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.19;
  letter-spacing: normal;
  text-align: left;
  padding: 0%;
  /*margin-top: 4%;*/

  margin-left: 0rem;
  margin-right: 0rem;
  /*margin-bottom: 3%;*/
  text-align: left;
  color: #252930;
}

/*
 #workArea {
    float: right;
    width: 78.7% !important;
    height: 100% !important;
    margin-top: 0rem !important;
    background-color: lawngreen;
    justify-content: center;
    z-index: 1;
    background-color: #f3f7fa;
  }

 
  .workAreaPage {
    width: 83.1%;
    height: 90.4%;

    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
  }

*/

/* 
#LandRegistryMain {
  width: 75%;
} */


.stageDescDiv {
  width: 8.437rem;
  height: 3.805rem;
  font-size: 1rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: left;
  color: #629ac4;
}

/* .LandRegistryTitle {
  display: block;
  width: 100%;
  height: 1.813rem;
  font-size: 1.5rem;
  color: #629ac4;
  text-align: left;
  margin-left: 4.473rem;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
  margin-top: 4.023rem;
  margin-bottom: 0.984rem;
} */

#SearchLandResultsTitle {
  /* width: 32.132rem;
  height: 4.392rem; */
  /*margin-left: 2rem;*/
  margin-top: 1rem;
  font-size: 0.85rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.88;
  letter-spacing: normal;
  text-align: left;
  color: #252930;
  width: 100%;
}

.SearchLandResults {
  /* width: 34.748rem !important;
  height: 7rem !important; */

  width: 19rem;
  min-width: 19rem;
  height: 7rem;
  border: solid 3px #dfe5ee;
  /*margin-left: 1.827rem;*/
  margin-right: 1.827rem;
  margin-top: 0.7rem;
  justify-content: center;
  justify-items: center;
  align-content: center;
  display: block;
}

#DNSearchLandResults {
  /* width: 34.748rem !important;
  height: 7rem !important; */

  width: 100%;
  height: 11rem;
  border: solid 3px #dfe5ee;
  /*margin-left: 1.827rem;
  margin-right: 1.827rem;*/
  margin-top: 0.42rem;
  justify-content: center;
  justify-items: center;
  align-content: center;
  display: block;
}
.DNPaymentForm
{
  padding-left:0rem !important;
  padding-right:0rem !important;
}
#DNSearchResultTitles {
  margin-top: 0rem;
  margin-left: 0rem;
  width: 100%;
  height: 1rem;
  display: inline-flex;
  padding-top: 0rem;
  font-size: 0.85rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 3;
  letter-spacing: normal;
  text-align: center;
  color: #252930;
  padding-right: 0.6rem;
  /* margin-bottom: 0.1rem; */
}

.ColumnTitle {
  width: 8rem !important;
  margin-left: 1.479rem;
  margin-top: 0rem;
  /*display: inline;*/
  color: #252930;
}

.ColumnTitle1 {
  width: 10rem !important;
  margin-left: 1.479rem;
  margin-top: 0.5rem;
  display: inline;
  color: #252930;
}

#DNSearchResultData {
  width: 100%;
  /* height: 40%; */
  margin-top: 0rem;
  margin-left: 0rem;
  display: inline-flex;
  /*margin-bottom: 5rem;*/
  margin-top: 0rem;
  margin-left: 0rem;
  padding-top: 0rem;
  /*padding-bottom: 10rem;*/

  font-size: 0.85rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 3.5;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
  padding-right: 0.6rem;
}

#SearchResultData1 {
  width: 100%;
  /* height: 40%; */
  margin-top: 0rem;
  margin-left: 0rem;
  display: inline-flex;
  margin-bottom: 0rem;
  margin-top: 0rem;
  margin-left: 0rem;
  padding-top: 0rem;
  padding-bottom: 0rem;

  font-size: 0.85rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 3.5;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
  padding-right: 0.6rem;
}

#SearchResultItemWrapper {
  width: 7.567rem;
  height: 2.218rem;
  width: 20%;
  display: inline-flex;
  margin-left: 4.5%;
  margin-bottom: 1rem;
  padding: 0.2rem;
  margin-top: 0.4rem;
  align-items: center;
  font-size: 0.85em;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 4.5;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
  justify-content: center;
  justify-items: center;
  align-content: center;
  vertical-align: middle;
  display: inline-flex;
}

#SearchResultItemToolTip {
  width: 100%;
  height: 2.218rem;
  background-color: #f5f5f5;
  display: inline-flex;
  margin-left: 4.5%;
  /*margin-bottom: 1rem;*/
  padding: 0.2rem;
  margin-top: 0.4rem;
  align-items: center;
  font-size: 0.85em;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 4.5;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
  justify-items: center;
  vertical-align: middle;
  display: inline-flex;
  padding-left: 0.8rem;
  border: 1px #629ac4 solid;
  border-radius: 25px;
}


#SearchResultItemToolTipWrapper {
  margin-left: 0.2rem;
  margin-right: 2.7rem;
  visibility: collapse;
}
.SearchResultItemDN {
  width: 9rem;
  /*height: 1.218rem;*/
  /*width: 20%;*/
  /*background-color: #f5f5f5;*/
  /*display: inline-flex;*/
  margin-left: 4.5%;
  /*margin-bottom: 1rem;*/
  /*padding: 0.2rem;*/
  /*margin-top: 0.4rem;*/
  /*align-items: center;*/
  /*font-size: 0.85em;*/
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  /*line-height: 4.5;*/
  letter-spacing: normal;
  text-align: left;
  color: #000000;
  /*justify-content: center;
  justify-items: center;
  align-content: center;
  vertical-align: middle;
  display: inline-flex;*/
}

.SearchResultItem1 {
  width: 10rem;
  height: 2.218rem;
  background-color: #f5f5f5;
  display: inline-flex;
  margin-left: 4.5%;
  margin-right:3%;
  margin-bottom: 1rem;
  padding: 0.2rem;
  margin-top: 0.4rem;
  align-items: center;
  font-size: 0.85em;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 4.5;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
  justify-content: center;
  justify-items: center;
  align-content: center;
  vertical-align: middle;
  display: inline-flex;
}

.SearchResultItem2 {
  width: 8.6rem;
  height: 2.218rem;
  background-color: #f5f5f5;
  display: inline-flex;
  margin-left: 4.5%;
  margin-bottom: 1rem;
  padding: 0.2rem;
  margin-top: 0.4rem;
  align-items: center;
  font-size: 0.85em;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 4.5;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
  justify-content: center;
  justify-items: center;
  align-content: center;
  vertical-align: middle;
  display: inline-flex;
}

#confirmButton {
  width: 5.784rem;
  height: 2.131rem;
  font-family: Titillium + Web;
  font-size: 1.125rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.94;
  letter-spacing: normal;
  text-align: center;
  background-color: #629ac4;
  color: #ffffff;
  /* justify-content: center; */
  /* justify-items: center; */
  /* align-content: center; */
  /* vertical-align: middle; */
  /* display: inline-flex; */
  /* justify-content: center; */
  /* justify-items: center; */
  /* align-content: center; */
  /* vertical-align: middle; */
  margin-top: 2rem;
  margin-left: 87%;
  padding: 0.1rem;
  border: none;
  /* float: right; */
  /*display: inline-flex; */
}

.btnLocateDN,
.btnUnPaidDN, 
.btnConfirmPay{
  margin: 0.5rem;
  width: 5.775rem !important;
  height: 2.128rem;
  background-color: #629ac4;
  color: white;
  font-size: 1rem;
  border: none;
  align-items: center;
  vertical-align: middle;
  justify-content: center;
}

.ColumnTitleDN {
  width: 12rem !important;
  margin-left: 0.2rem;
  margin-top: 0rem;
  /*display: inline;*/
  color: #252930;
}

.DemandNoticeResults
{
  width:19rem;
  height:15rem;
  border: solid 3px #dfe5ee;
  /*margin-left: 1.827rem;*/
  /*margin-right: 1.827rem;*/
  margin-top: 0.7rem;
  justify-content: center;
  justify-items: center;
  align-content: center;
  display: block;
}

/*.UnPaidDNTitleDiv {
  margin-left: -1rem !important;
}*/

.ProgressBar {
  width: 3rem;
  height: 3rem;
  background-repeat: no-repeat;
  z-index: 100;
}

@keyframes pageAnimationPayments {
  from {
    width: 1px;
    height: 1px;
    top: 270px;
    left: 288px;
  }
  to {
    width: 63.2rem;
    height: 31rem;
    top: 103px;
    left: 323px;
  }
}

.DemandNoticeDDLButtonDiv
{
  float: right;
  margin-top: 1rem;
}
.PenaltiesArrerasMessage
{
  margin-top:0.5rem;
  font-size: 0.85rem;
}

@media only screen and (min-width: 860px) and (max-width: 1024px) {
  .SearchLandResults {
    width: 19rem;
    min-width: 19rem;
    height: 7rem;
    border: solid 3px #dfe5ee;
    /* margin-left: 1.827rem; */
    margin-right: 1.827rem;
    margin-top: 0.7rem;
    margin-bottom: 2rem !important;
    justify-content: center;
    justify-items: center;
    align-content: center;
    display: block;
  }

  .SearchResultItem1 {
    width: 10rem;
    height: 1.8rem;
    background-color: #f5f5f5;
    display: inline-flex;
    margin-left: 3%;
    margin-right: 10%;
    margin-bottom: 0.5rem;
    padding: 0.2rem;
    margin-top: 0.2rem;
    align-items: center;
    font-size: 0.85em;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 4;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
    justify-content: center;
    justify-items: center;
    align-content: center;
    vertical-align: middle;
    display: inline-flex;
}

.ColumnTitle1 {
  width: 10rem !important;
  margin-left: 10%;
  margin-top: 0.3rem;
  display: inline;
  color: #252930;
}
}

@media only screen and (min-width: 1000px){
  .btnLocateDN,
  .btnUnPaidDN, 
  .btnConfirmPay{
    margin: 0.5rem;
    width: 5.775rem !important;
    height: 2.128rem;
    background-color: #629ac4;
    color: white;
    font-size: 1rem;
    border: none;
    align-items: center;
    vertical-align: middle;
    justify-content: center;
  }
  
  #PaymentsPaymentDataForm {
    display: flex;
    justify-content: left;
    align-content: center;
    align-items: center;
    /*margin-left: 1.942rem;*/
    width: 100%;
  }

  #DNSearchLandResultsPay {
    /* width: 34.748rem !important;
    height: 7rem !important; */
  
    width: 100%;
    border: solid 3px #dfe5ee;
    /*margin-left: 1.827rem;
    margin-right: 1.827rem;*/
    margin-top: 0.2rem;
    justify-content: center;
    justify-items: center;
    align-content: center;
    display: block;
  }


  #LandName,
  #DemandNotice,
  #demandNoticeDDL,
  #AppNumber {
    width: 100% !important;
    min-width: 19rem !important;
    background-color: #dfe5ee !important;
  }
  .confirmDNButtonsDiv {
    display: inline-flex;
    width: 100%;
    margin-top: 0.4rem;
    /*margin-left: 1.8rem;*/
    position: relative;
    justify-content: flex-end;
    place-content: flex-end;
    align-content: flex-end;
  }

  .confirmButtonDiv {
    /* width: 5.784rem;
    height: 2.131rem; */
    /* background-color: #629ac4; */
    justify-content: center;
    justify-items: center;
    align-content: center;
    vertical-align: middle;
    margin-top: -10rem;
    margin-right: 0rem;
    float: right;
    display: inline-flex;
  }

  #LandRegistryWrapper {
    display: flex;
    justify-items: center;
    margin-left: 1.75rem;
    /*margin-top: 1.479rem;
    margin-bottom: 1.479rem;*/
    margin-right: 1.75rem;
    /*height: 31.2rem !important;*/
    height:91.5% !important;
    max-width: 100% !important;
    background-color: #f3f7fa;
  }

  #LandRegistryData {
    display: inline-block !important;
    width: 55%;
    /* justify-content: flex-start; */
    float: left;
    padding-left: 2rem;
    /* padding-left: 2.5rem; */
  }

  #LandRegistryStages {
    display: inline-block;
    justify-content: center;
    /* justify-content: space-between; */
    width: 40% !important;
    /* float: center !important;
    margin-right: 10rem; */
    /* margin-left: 60%; */
    /*margin-left: 5rem;*/
    padding-left: 20%;
    margin-top: 0rem;
    /* container d-block justify-content-center */
    position: relative;
  }

  .LandRegistryStages{
    display: flex;
    justify-content: center;
    width:19rem;
  }

  #SearchFormDiv {
    display: flex;
    justify-items: flex-start;
    place-items: flex-start;
    place-content: flex-start;
    margin-top: 0rem;
    /*padding-left: 2.5rem;*/
    /*width: 76%;*/
  }
  .UnPaidDNDiv {
    width: 100%;
    float: left;
  }
  #PaymentsWrapper {
    display: flex;
    justify-items: center;
    margin-left: 1.75rem;
    /*margin-top: 1.479rem;
    margin-bottom: 1.479rem;*/
    margin-right: 1.75rem;
    /*height: 31.2rem !important;*/
    height:91.5%;
    max-width: 100% !important;
    /*background-color: #f3f7fa;*/
  }
  /*.PaymentsControlWrapper
  {
    width:26.7rem;
  }*/
}

@media only screen and (min-width: 50px) and (max-width: 900px) {
  #workArea
  {
    width:100%;
    margin-left:0rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    z-index: 1;
  }
  #Content
  {
    margin-left:0rem !important;
    height: 900px;
  }
  #LandRegistryWrapper {
    display: block;
    /*justify-items: center;*/
    margin-left: rem;
    /*margin-top: 1.479rem;
    margin-bottom: 1.479rem;*/
    margin-right: 1rem;
    height: 100% !important;
    /*max-width: 100% !important;*/
    /*background-color: #f3f7fa;*/
    /* height: 30em !important; */
    z-index: 1;
    overflow-y: scroll !important;
  }
  input#LandName,input#DemandNotice
  {
    width:17rem !important;
  }
  .LandRegistryTitle {
    /* width: 9.938rem;
    height: 1.813rem; */
    font-size: 1.5rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: normal;
    text-align: left;
    color: #629ac4;
    /*margin-top: 2.757rem;*/
    /*margin-left: 3.942rem;*/
    display: block;
    width:20rem;
    height:2rem;
    margin-bottom:0.5rem;
  }
  .ColumnTitle1 {
    width: 10rem !important;
    margin-left: 10%;
    margin-top: 0.5rem;
    display: inline;
    color: #252930;
  }
  .SearchFormDesc {
    display: block;
    font-size: 0.85rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.19;
    letter-spacing: normal;
    text-align: left;
  
    margin-top: 1rem;
    /*margin-left: 2rem;*/
    text-align: left;
    color: #252930;
  }

  #LandRegistryData {
    display: inline-block !important;
    width: 100%;
    min-width: 19rem;
    /* justify-content: flex-start; */
    /* padding-left: 2.5rem; */
  }

  #LandRegistryStages {
    display: inline-block;
    justify-content: center;
    /* justify-content: space-between; */
    /*width: 40% !important;*/
    /*float:left;*/
    /* float: center !important;
    margin-right: 10rem; */
    /* margin-left: 60%; */
    /*margin-left: 5rem;*/
    /*padding-left: 20%;*/
    margin-top: 0rem;
    width:20rem !important;
    /* container d-block justify-content-center */
    /*position: relative;*/
  }

  #SearchFormDiv {
    display: flex;
    justify-items: flex-start;
    place-items: flex-start;
    place-content: flex-start;
    margin-top: 0rem;
    /*padding-left: 2.5rem;*/
    /*width: 99.9%;*/
  }

  #SearchLandResultsTitle {
    /* width: 32.132rem;
    height: 4.392rem; */
    /*margin-left: 2rem;*/
    margin-top: 1rem;
    font-size: 0.85rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.88;
    letter-spacing: normal;
    text-align: left;
    color: #252930;
    width: 100%;
  }

  .SearchLandResults {
    /* width: 34.748rem !important;
    height: 7rem !important; */
  
    width: 19rem;
    min-width: 19rem;
    height: 7rem;
    border: solid 3px #dfe5ee;
    /*margin-left: 1.827rem;*/
    margin-right: 1.827rem;
    margin-top: 0.7rem;
    justify-content: center;
    justify-items: center;
    align-content: center;
    display: block;
  }

  #DNSearchLandResultsPay {
    /* width: 34.748rem !important;
    height: 7rem !important; */
  
    width: 19rem;
    height: 15rem;
    border: solid 3px #dfe5ee;
    margin-top: 0.2rem;
    justify-content: center;
    justify-items: center;
    align-content: center;
    display: block;
  }
  
  #PaymentsPaymentDataForm {
    display: flex;
    justify-content: left;
    align-content: center;
    align-items: center;
    width: 19rem;
  }
  .DNPaymentForm
  {
    padding-left:0rem !important;
    padding-right:0rem !important;
  }

  .ColumnTitle {
    width: 8rem !important;
    margin-left: 1.479rem;
    margin-top: 0rem;
    /*display: inline;*/
    color: #252930;
  }

  
.SearchResultItem1 {
  width: 10rem;
  height: 2.218rem;
  background-color: #f5f5f5;
  display: inline-flex;
  margin-left: 3%;
  margin-right:10%;
  margin-bottom: 1rem;
  padding: 0.2rem;
  margin-top: 0.4rem;
  align-items: center;
  font-size: 0.85em;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 4.5;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
  justify-content: center;
  justify-items: center;
  align-content: center;
  vertical-align: middle;
  display: inline-flex;
}

  .SearchResultItemDN {
    width: 9rem;
    /*height: 1.218rem;*/
    /*width: 20%;*/
    /*background-color: #f5f5f5;*/
    /*display: inline-flex;*/
    margin-left: 4.5%;
    /*margin-bottom: 1rem;*/
    /*padding: 0.2rem;*/
    /*margin-top: 0.4rem;*/
    /*align-items: center;*/
    /*font-size: 0.85em;*/
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    /*line-height: 4.5;*/
    letter-spacing: normal;
    text-align: left;
    color: #000000;
    /*justify-content: center;
    justify-items: center;
    align-content: center;
    vertical-align: middle;
    display: inline-flex;*/
  }

  .confirmDNButtonsDiv {
    display: flex;
    width: 19rem;
    min-width:19rem;
    margin-top: 0.5rem;
    flex-direction: row-reverse;
  }

  .ColumnTitleDN {
    width: 12rem !important;
    margin-left: 0.2rem;
    margin-top: 0rem;
    /*display: inline;*/
    color: #252930;
  }

  #LandName,
  #DemandNotice,
  #demandNoticeDDL,
  #AppNumber {
  /*width: 100% !important;*/
  min-width: 19rem !important;
  background-color: #dfe5ee !important;
  }
  .DemandNoticeResults
  {
    width:19rem;
    height:15rem;
    border: solid 3px #dfe5ee;
    /*margin-left: 1.827rem;*/
    /*margin-right: 1.827rem;*/
    margin-top: 0.7rem;
    justify-content: center;
    justify-items: center;
    align-content: center;
    display: block;
  }
  #PaymentsWrapper {
    display: flex;
    justify-items: center;
    margin-left: 1rem;
    /*margin-top: 1.479rem;
    margin-bottom: 1.479rem;*/
    margin-right: 1rem;
    /*height: 31.2rem !important;*/
    height:91.5%;
    max-width: 100% !important;
    width:19rem;
    /*background-color: #f3f7fa;*/
  }
  .UnPaidDNDiv {
    width: 100%;
    float: left;
  }
  .PaymentsControlWrapper
  {
    width:19rem;
  }




}
