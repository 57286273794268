@keyframes pageAnimationSideBarMobileDown {
    from {
    width: 100%;
    height: 0;
    top: -1rem;
    left:0rem;
    }
    to {
    width: 100%;
    height: 32%;
    top: 0rem;
    left:0rem;
    }
}

.overlay {
    position: absolute;
    left: 0; /* Adjusted to align left */
    width: 100%;
    height:32%;
    background-color: white;
    z-index: 1000;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    /*margin-top:5rem !important;*/
    animation-name: pageAnimationSideBarMobileDown;
    animation-duration: 0.5s;
    overflow: hidden;
  }

  .overlay.active {
    height: 33%; /* Set the desired final height */
    opacity: 1; /* Set opacity to 1 when the overlay is active */
    margin-top:5rem !important;
    width: 100%;
    list-style: none;
    list-style-type: none;  
  }
  
  .overlay ul {
    list-style: none;
    list-style-type: none;
    padding: 0;
  }
  
  .overlay li {
    margin-bottom: 10px;
    
  }
  

.overlay li:active,
.overlay li:hover,
.overlay li:visited,
.overlay a.active .overlay a.hover,
.overlay a.visited,
.overlay span.active,
.overlay span.hover,
.overlay span.visited {
  border-right-color: #1046ce !important;
}


.HomeMenuActive,
.AboutUsActive,
.ContactUsActive {
  border-right-color: #1046ce !important;
  border-right-width: 0.313rem;
  border-right-style: solid;
}


.PaymentsActive {
  border-right-color: #e1a417;
  border-right-width: 0.313rem;
  border-right-style: solid;
  color: #e1a417 !important;
}

.LandRegisterActive {
  border-right-color: #e25824;
  border-right-width: 0.313rem;
  border-right-style: solid;
  color: #e25824 !important;
}

.PersonalZoneActive {
  border-right-color: #279762;
  border-right-width: 0.313rem;
  border-right-style: solid;
  color: #279762 !important;
}

.AppFileTrackingActive {
  border-right-color: #71a543;
  border-right-width: 0.313rem;
  border-right-style: solid;
  color: #71a543 !important;
}

.HomeMenuTitleActive,
.AboutUsTitleActive,
.ContactUsTitleActive {
  color: #1046ce !important;
}

.PaymentTitleActive {
  color: #e1a417 !important;
}
.LandRegisterTitleActive {
  color: #e25824 !important;
}
.PersonalZoneTitleActive {
  color: #279762 !important;
}

.AppFileTrackingTitleActive {
  color: #71a543 !important;
}
  
/*
@media only screen and (min-width: 370px) and (max-width: 670px) {
    @keyframes pageAnimationSideBarMobileDown {
        from {
        width: 100%;
        height: 0;
        top: -1rem;
        left:0rem;
        }
        to {
        width: 100%;
        height: 35%;
        top: 0rem;
        left:0rem;
        }
    }

    .overlay {
        position: absolute;
        left: 0; 
        width: 100%;
        height:35% !important;
        background-color: white;
        z-index: 1000;
        padding: 20px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
        animation-name: pageAnimationSideBarMobileDown;
        animation-duration: 0.5s;
        overflow: hidden;
      }
}*/
/*
@media only screen and (min-width: 280px) and (max-width: 660px) {
    @keyframes pageAnimationSideBarMobileDown {
        from {
        width: 122%;
        height: 0;
        top: -1rem;
        left:0rem;
        }
        to {
        width: 115%;
        height: 35%;
        top: 0rem;
        left:0rem;
        }
    }

    .overlay {
        position: absolute;
        left: 0; 
        width: 122% !important;
        height:35% !important;
        background-color: white;
        z-index: 1000;
        padding: 20px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
        animation-name: pageAnimationSideBarMobileDown;
        animation-duration: 0.5s;
        overflow: hidden;
      }
}*/