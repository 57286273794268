@import "./styles/popupsPages.css";
@import "./styles/login.css";
@import "./styles/personalZone.css";
@import "./styles/landregistry.css";
@import "./styles/stages.css";
@import "./styles/home.css";
@import "./styles/navbar.css";
@import "./../node_modules/bootstrap/dist/css/bootstrap.css";
@import "./../node_modules/font-awesome/css/font-awesome.css";


@keyframes pageAnimationSideBarMobileReverse {
  from {
    width: 22.5rem;
    height: 30%;
    top: 3.5rem;
    left:-0.5rem;
  }
  to {
    width: 22.5rem;
    height: 30%;
    top: 3.5rem;
    left:-22.5rem;
  }
}


.App {
  width: 100% !important;
  height: 100% !important;
  background-color: #f3f7fa;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  overflow: hidden;
  min-width: 22.5rem !important;
}

.inlineDiv {
  display: inline-flex;
  width: 100%;
  height: 100%;
  padding: 0rem;
  z-index: -1;
  min-width: 22.5rem !important;
}

main {
  margin: 0;
  padding: 0;
  width: 100% !important;
  height: 100% !important;
  background-color: #f3f7fa;
  /* min-width: 22.5rem !important; */
}

#logoDiv {
  height: 4.827rem !important;
  margin-top: 0rem;
  align-items: center;
  vertical-align: middle;
  z-index: 1;
  display: inline-flex;
  background-color: white;
  border: none;
  width:21% !important;
}

#logoDivMobile {
  display: none;
}

#mainNavBar {
  width: 79% !important;
  height: 4.827rem !important;
  margin-top: 0rem;
  margin-left: 0rem;
  box-shadow: 0 0.188rem 0rem 0 rgba(0, 0, 0, 0.16);
  background-color: #f3f7fa;
  display: inline-flex;
  z-index: 1;
}

.logo_Zambia {
  width: 3.474rem;
  height: 4.015rem;
  object-fit: contain;
  margin-top: 0.541rem !important;
  margin-left: 1.329rem !important;
}

#offlineMessage {
  text-align:center;
  margin-top:7rem;
  margin-left:1rem;
  padding-right: 5%;
  padding-left:5%;
}

.Republic-of-Zambia {
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
  margin-left: 1.195rem;
}
/* }  */

#MenuBtn {
  height:2.7rem;
  width:3rem;
  padding-right:8px;
  padding-left:8px;
}

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}

.rf {
  float: right;
}

.searchButtonDiv {
  /* width: 2.261rem;
  height: 2.131rem; */
  width: 5.4%;
  height: 3.8%;
  background-color: #629ac4;
}

.smallBtnDiv {
  background-color: #629ac4;
  border: none;
  color: white;
  cursor: pointer;
  width: 1.9rem;
  height: 1.8rem;
  /* width: 2.261rem;
  height: 2.131rem; */
  justify-content: center;
  place-items: center;
  place-content: center;
  align-content: center;
  vertical-align: center !important;
  vertical-align: center;
}

/* Darker background on mouse-over */
.smallBtn:hover {
  background-color: #629ac4;
}

.search-button {
  display: flex;
  align-items: center;
  background-color: #629ac4;
  cursor: pointer;
  border: none;
  color: white;
  width: 6rem;
  height: 2.5rem;
  margin-bottom:4rem !important;
}

.search-icon {
  margin-left: 10%; 
  width: 0.793rem;
  height: 0.812rem;
}

.searchBtn {
  background-image: url("components/images/smallSearch.svg");
  background-repeat: no-repeat;
  background-color: #629ac4;
  width: 0.793rem;
  height: 0.812rem;
  object-fit: contain;
  border: none;
  margin: 25% !important;
  justify-content: center;
  align-content: center;
  vertical-align: center;
  vertical-align: center;
  cursor: pointer;
  vertical-align: super;
}

.inputTxt {
  width: 21.397rem;
  height: 2.128rem;
  background-color: #dfe5ee;
}

.menu1 {
  margin-right:3rem;

}

.smallCloseBtnDivHelp {
  display: block !important;
  background-color: #629ac4;
  color: white;
  cursor: pointer;
  width: 2rem !important;
  height: 2rem !important;
  /* width: 2.261rem !important;
  height: 2.131rem !important; */
  margin-left: 96%;
  margin-right: 0rem;
  margin-top: -0rem;
  padding-top: 0rem;
  padding-left: 0.75rem;
}
.smallCloseBtnDivOneTimeLogin {
  display: block !important;
  background-color: #629ac4;
  color: white;
  cursor: pointer;
  width: 2rem !important;
  height: 2rem !important;
  /* width: 2.261rem !important;
  height: 2.131rem !important; */
  margin-left: 95.8%;
  margin-right: 0rem;
  margin-top: -0rem;
  padding-top: 0rem;
  padding-left: 0.75rem;
}

.closeBtn {
  background-image: url("components/images/closeBtn.svg");
  background-repeat: no-repeat;
  background-color: #629ac4;
  width: 0.643rem;
  height: 0.643rem;
  object-fit: contain;
  border: none;
  margin-right: 0.8rem;
  margin-top: 0.6rem !important;
  margin-left: 0.8rem;
}

.btnConfirm {
  margin: 0.5rem;
  min-width: 5.775rem;
  height: 2.128rem;
  background-color: #629ac4;
  color: white;
  font-size: 1rem;
  border: none;
  align-items: center;
  vertical-align: middle;
  justify-content: center;
}
.btnCancel {
  margin: 0.5rem;
  min-width: 5.775rem;
  height: 2.128rem;
  background-color: white;
  color: #629ac4;
  font-size: 1rem;
  border: none;
  align-items: center;
  vertical-align: middle;
  justify-content: center;
}

.FormButtonsDiv2 {
  display: inline-flex;
  width: 100%;
  /* position: relative; */
  justify-content: flex-end !important;
  align-content: flex-end !important;
  place-content: flex-end !important;
  justify-content: flex-end !important;
  align-content: flex-end !important;
  place-content: flex-end !important;
  margin: 0.5rem 0rem 0.5rem 5.85rem !important;
}
.btnClose {
  margin: 0.5rem;
  padding: 0.4rem 0.4rem 0rem 0.4rem;
  height: 2.5rem;
  background-color: #629ac4;
  color: white;
  font-size: 1rem;
  border: none;
  align-items: center;
  vertical-align: middle;
  justify-content: center;
}

.TooltipWrapper {
  /* margin-left: 1rem; */
  /* margin-top: 0.4rem; */
  /* margin-bottom: 5%; */
  margin-left: -0.25rem;
  padding: 0rem 0.5rem 0rem 0.5rem !important;
  border: 1px #629ac4 solid;
  border-radius: 1px;
  width: auto !important;
  position: absolute;
  background-color: #f5f5f5;
  /*display: inline;*/
  z-index: 5;
}

.NoDataMessage {
  background-color: #ffffff;
  color: #000000;
  font-size: 0.85rem;
  /* border: 0.01rem solid red; */
  margin-left: 4.5rem;
  /* height: 1rem; */
  margin-top: 0.5rem;
  display: inline-flex;
  place-content: left;
  /* width: 100% !important; */
}





/*********************************************** NEW ***********************************************************************/

/* default styles here for older browsers. 
       I tend to go for a 600px - 960px width max but using percentages
    */

/* MOBILES */

/* This section is to handle the mobile screen flip from portrait <-> landscape , so the display will always look like portrait
   currently this issue is disabled due to the fact that it is not done , when clicking on the side menu button , we still see the page underneath*/

/*@media screen and (min-device-width: 50px) and (max-device-width: 900px) and (orientation:landscape) {
  main {
    margin: 0;
    padding: 0;
    width: 100% !important;
    height: 360px !important;

    background-color: white;
  }
  body{
    width:360px !important;
    transform: rotate(-90deg);
  }
}*/

@media only screen and (min-device-width: 50px) and (max-device-width: 900px) {
  /* styles for mobile browsers smaller than 480px; (iPhone) */
  App {
    width: 100% !important;
    height: 100% !important;
    /* background-color: #f3f7fa; */
    background-color: white;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    /* font-size: 1rem; */
    overflow: hidden;
    min-width: 100% !important;
    align-content: center;
    align-items: center;
    /* min-width: 22.5rem !important; */
    /* box-shadow: 0 0.188rem 0rem 0 rgba(0, 0, 0, 0.16); */
  }

  .inlineDiv {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    display: inline-flex;
    width: 100%; 
    height: 5rem;
    padding-right:0.5rem;
    padding-left:0.5rem;
    background-color: white;
    box-shadow: 0 0.188rem 0rem 0 rgba(0, 0, 0, 0.16);
    z-index: 2;
  }


  /* .inlineDiv {
    display: inline-flex;
    width: 100%;
    height: 100%;
    padding: 0rem;
    z-index: -1;
    min-width: 22.5rem !important;
  }

   */

  main {
    margin: 0;
    padding: 0;
    width: 100% !important;
    height: 100%;
    /*margin-top:5rem !important;
    min-height:38rem !important;*/
    background-color: white;
    /* min-width: 22.5rem !important; */
  }

  #logoDiv {
    height: 4.827rem !important;
    width:3rem !important;
    margin-top: 0rem;
    width: 25% !important;
    margin-left: 0;
    align-items: center;
    vertical-align: middle;
    z-index: 2;
    display: inline-flex;
    background-color: white;
    border: none;
    padding: 0.5rem 1rem;
  }
  #logoDiv a{ 
    width:5rem !important;
    padding-right:0.5rem;
  }

  .logo_Zambia {
    /* width: 3.474rem;
    height: 4.015rem; */
    width: 3.7rem !important;
    height: 3.5rem !important;

    object-fit: contain;
    margin-top: 0.4rem !important;
    margin-left: 0rem !important;
    
    margin-bottom: 0.4rem !important;
  }

  /* #logoDivMobile {
    height: 4.827rem !important;
    margin-top: 0rem;
    width: 21.4% !important;
    margin-left: 0;
    align-items: center;
    vertical-align: middle;
    z-index: 12;
    display: inline-flex;
    background-color: white;
    border: none;
  } */
  /* #logoDiv {
    display: none;
  } */
  #mainNavBar {
    width: 75% !important;
    height: 4.827rem !important;
    margin-top: 0rem;
    /*margin-left: 2.5rem;*/
    margin-left:0.2rem; /* Changed by Shlomy Akons 20/05/2021 */
    /* box-shadow: 0 0.188rem 0rem 0 rgba(0, 0, 0, 0.16); */
    background-color: white;
    display: inline-flex;
    z-index: 2;
    box-shadow: none;
  }

  .Republic-of-Zambia {
    display: none;
  }
  .FormButtonsDiv {
    display: inline-flex;
    width: 100%;
    position: relative;
    justify-content: flex-end !important;
    align-content: flex-end !important;
    place-content: flex-end !important;
    justify-content: flex-end !important;
    align-content: flex-end !important;
    place-content: flex-end !important;
    margin: 0.5rem 0rem 0.5rem 0rem !important;
    padding-right: 2.3rem;
  }
  .smallCloseBtnDiv {
    display: block !important;
    background-color: #629ac4;
    color: white;
    cursor: pointer;
    width: 2rem !important;
    height: 2rem !important;
    /* width: 2.261rem !important;
    height: 2.131rem !important; */
    margin-left: 88.1%;
    margin-right: 0rem;
    margin-top: -0rem;
    padding-top: 0rem;
    padding-left: 0.75rem;
  }
  .smallCloseBtnDiv1 {
    display: block !important;
    background-color: #629ac4;
    color: white;
    cursor: pointer;
    width: 2rem !important;
    height: 2rem !important;
    /* width: 2.261rem !important;
    height: 2.131rem !important; */
    margin-left: 88%;
    margin-right: 0rem;
    margin-top: 0rem;
    padding-top: 0rem;
    padding-left: 0.75rem;
  }
}
@media only screen and (min-device-width: 350px) and (max-device-width: 800px) {
  /* styles for Galaxy S21 */

  #logoDiv {
    height: 4.827rem !important;
    width:4rem !important;
    margin-top: 0rem;
    width: 32.5% !important;
    margin-left: 0;
    align-items: center;
    vertical-align: middle;
    z-index: 2;
    display: inline-flex;
    background-color: white;
    border: none;
    padding: 0.4rem 0.9rem;
  }
  #logoDiv a{ 
    width:5.5rem !important;
    padding-right:0.3rem;
  }

  .logo_Zambia {
    /* width: 3.474rem;
    height: 4.015rem; */
    width: 4rem !important;
    height: 3.8rem !important;

    object-fit: contain;
    margin-top: 0.2rem !important;
    margin-left: 0rem !important;
    
    margin-bottom: 0.2rem !important;
  }

  .inlineDiv {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    display: inline-flex;
    width: 85%; 
    height: 5rem;
    padding-right:0.5rem;
    padding-left:0.5rem;
    background-color: white;
    box-shadow: 0 0.188rem 0rem 0 rgba(0, 0, 0, 0.16);
    z-index: 2;
  }

}


@media only screen and (min-device-width: 380px) and (max-device-width: 855px) {
  /* styles for Galaxy S21 */

  #logoDiv {
    height: 4.827rem !important;
    width:4rem !important;
    margin-top: 0rem;
    width: 32.5% !important;
    margin-left: 0;
    align-items: center;
    vertical-align: middle;
    z-index: 2;
    display: inline-flex;
    background-color: white;
    border: none;
    padding: 0.4rem 0.9rem;
  }
  #logoDiv a{ 
    width:5.5rem !important;
    padding-right:0.3rem;
  }

  .logo_Zambia {
    /* width: 3.474rem;
    height: 4.015rem; */
    width: 4rem !important;
    height: 3.8rem !important;

    object-fit: contain;
    margin-top: 0.2rem !important;
    margin-left: 0rem !important;
    
    margin-bottom: 0.2rem !important;
  }

  .inlineDiv {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    display: inline-flex;
    width: 100%; 
    height: 5rem;
    padding-right:0.5rem;
    padding-left:0.5rem;
    background-color: white;
    box-shadow: 0 0.188rem 0rem 0 rgba(0, 0, 0, 0.16);
    z-index: 2;
  }

}

@media only screen and (min-width: 1000px)
{
  .colRegister{
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%;
    padding-right: 0rem !important;
    padding-left: 0rem !important;
  }
  .FormButtonsDiv {
    display: inline-flex;
    width: 100%;
    position: relative;
    justify-content: flex-end !important;
    align-content: flex-end !important;
    place-content: flex-end !important;
    justify-content: flex-end !important;
    align-content: flex-end !important;
    place-content: flex-end !important;
    margin: 0.5rem 0rem 0.5rem 0rem !important;
  }
  
  .smallCloseBtnDiv {
    display: block !important;
    background-color: #629ac4;
    color: white;
    cursor: pointer;
    width: 2rem !important;
    height: 2rem !important;
    /* width: 2.261rem !important;
    height: 2.131rem !important; */
    margin-left: 93.4%;
    margin-right: 0rem;
    margin-top: -0rem;
    padding-top: 0rem;
    padding-left: 0.75rem;
  }
  .smallCloseBtnDiv1 {
    display: block !important;
    background-color: #629ac4;
    color: white;
    cursor: pointer;
    width: 2rem !important;
    height: 2rem !important;
    /* width: 2.261rem !important;
    height: 2.131rem !important; */
    margin-left: 93.4%;
    margin-right: 0rem;
    margin-top: 0rem;
    padding-top: 0rem;
    padding-left: 0.75rem;
  }
}


/* for all mobile/cellphone widths */
@media only screen and (min-width: 200px) and (max-width: 900px) and (orientation: portrait) {
  /* //Put your CSS here for 200px to 767px width devices (cover all mobile portrait width // */
  #logoDiv {
    height: 4.827rem !important;
    width:4rem !important;
    margin-top: 0rem;
    width: 32.5% !important;
    margin-left: 0;
    align-items: center;
    vertical-align: middle;
    z-index: 2;
    display: inline-flex;
    background-color: white;
    border: none;
    padding: 0.4rem 0.9rem;
  }
}

/* for all mobile/cellphone widths */
@media only screen and (min-width: 200px) and (max-width: 900px) and (orientation: landscape) {
  /* //Put your CSS here for 200px to 767px width devices (cover all mobile portrait width // */
}

/* TABLETS*/
@media only screen and (min-width: 860px) and (max-width: 1024px) {
  /* //Put your CSS here for 768px to 1024px width devices(covers all iPad portrait width //
    */
  App {
    width: 100% !important;
    height: 100% !important;

    background-color: white;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    overflow: hidden;
    min-width: 100%;
    min-width: 22.5rem !important;
  }

  .inlineDiv {
    display: inline-flex;
    width: 100%;
    height: 100%;
    padding: 0rem;
    z-index: -1;
    min-width:22.5rem !important;
  }

  /*
  #logoDiv {
    height: 4.827rem !important;
    width:4rem !important;
    margin-top: 0rem;
    width: 32.5% !important;
    margin-left: 0;
    align-items: center;
    vertical-align: middle;
    z-index: 2;
    display: inline-flex;
    background-color: white;
    border: none;
    padding: 0.4rem 0.9rem;
  }
  #logoDiv a{ 
    width:5.5rem !important;
    padding-right:0.3rem;
  }*/

  .logo_Zambia {
    /* width: 3.474rem;
    height: 4.015rem; */
    width: 5rem !important;
    height: 4.5rem !important;
    object-fit: contain;
    margin-top: 1rem !important;
    margin-left: 0rem !important;
    margin-bottom: 0.2rem !important;
  }
/*
  .inlineDiv {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    display: inline-flex;
    width: 100%; 
    height: 5rem;
    padding-right:0.5rem;
    padding-left:0.5rem;
    background-color: white;
    box-shadow: 0 0.188rem 0rem 0 rgba(0, 0, 0, 0.16);
    z-index: 1;
  }
*/



  main {
    margin: 0;
    padding: 0;
    width: 100% !important;
    height: 100% !important;

    background-color: white;
    /* min-width: 22.5rem !important; */
  }

  /* #logoDiv {
    height: 5rem !important;
    margin-top: 0rem;
    width: 25% !important;
    margin-left: 20rem;
    align-items: center;
    vertical-align: middle;
    z-index: 1;
    display: inline-flex;
    background-color: white;
    box-shadow: 0 0.188rem 0rem 0 rgba(0, 0, 0, 0.16);
    border: none;
  } */
/*
  #mainNavBar {
    width: 75% !important;
    height: 4rem !important;
    margin-top: 0rem;
    margin-left: 0.75rem;
    background-color: white;
    display: inline-flex;
    z-index: 1;
    box-shadow: none;
    border:none;
  }*/

  #mainNavBar {
    width: 79% !important;
    height: 4.827rem !important;
    margin-top: 0rem;
    margin-left: 0rem;
    background-color: #f3f7fa;
    box-shadow: 0 0.188rem 0rem 0 rgba(0, 0, 0, 0.16);
    display: inline-flex;
    z-index: 1;
  }

  #MenuBtn {
    height:2.7rem;
    width:3rem;
    padding-right:8px;
  }
/*
  #logoDiv {
    height: 4rem !important;
    width:2.5rem !important;
    margin-top: 0rem;
    width: 10% !important;
    margin-left: 0;
    align-items: center;
    vertical-align: middle;
    z-index: 2;
    display: inline-flex;
    background-color: white;
    border: none;
    padding: 0.5rem 1rem;
  }*/

  #logoDiv {
    height: 4.827rem !important;
    width:35% !important;
    margin-top: 0rem;
    align-items: center;
    vertical-align: middle;
    z-index: 1;
    display: inline-flex;
    background-color: white;
    border: none;
    margin-left:1rem;
  }

}

/* different techniques for iPad screening */
/* For portrait layouts only */

@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
}
/* For landscape layouts only */

@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: landscape) {
}

/*DESKTOPS*/
/* (1920x1080) Full HD Display */
@media screen and (max-width: 1920px) {
}
/* (1366x768) WXGA Display */
@media screen and (max-width: 1366px) {
}
/* styles for browsers larger than 960px; */
@media only screen and (min-width: 960px) {
}
/* styles for browsers larger than 1440px; */

@media only screen and (min-width: 1440px) {
}
@media only screen and (min-width: 2000px) {
  /* for sumo sized (mac) screens */
}




/*********************************************** NEW ***********************************************************************/
